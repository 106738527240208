import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { Button, CardContent, Typography, useTheme } from "@material-ui/core";
import useTypedTranslation from "../Hooks/useTypedTranslation";
import {
  finishLockdownPlan,
  getLockdownWorkflows,
  getWorkflows,
  startLockdownPlan,
} from "../../API/apiWorkflow";
import { toast } from "react-toastify";
import LoadingButton from "../LoadingButton";
import { shallowEqual, useSelector } from "react-redux";
import StoreStateModel from "../../Models/store";
import { EWorkflowCodeTypes, WorkflowModel } from "../../Models/workflow";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //
    },
    spaceTop: {
      marginTop: theme.spacing(2),
    },
  })
);
export default function LockdownPlans(): JSX.Element {
  const { t } = useTypedTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [workflowLockdownPlanList, setWorkflowLockdownPlanList] = useState<WorkflowModel[]>([]);

  const isGeneralLockDownActived = useSelector(
    (state: StoreStateModel) => state.general.isGeneralLockdownActivated,
    shallowEqual
  );
  const isActive = isGeneralLockDownActived;
  useEffect(() => {
    const load = async () => {
      setLoading(true);

      await getLockdownWorkflows()
        .then((response) => {
          try {
            if (!response.data.hasErrors) {
              const temp: WorkflowModel[] = response.data.payload;
              setWorkflowLockdownPlanList(
                temp.filter((value) => value.code === EWorkflowCodeTypes.wf_lockdown_start)
              );
            }
          } catch (error) {
            //
          }
        })
        .catch((error: any) => {
          // manage in the interceptor
        })
        .finally(() => {
          setLoading(false);
        });
    };
    load();
  }, []);
  const startingLockDownPlan = async () => {
    try {
      setLoading(true);
      const response = await startLockdownPlan();
      if (!response.data.hasErrors) {
        toast.success(t("Lockdown"));
      }
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  };
  const revertLockDownPlan = async () => {
    try {
      setLoading(true);
      const response = await finishLockdownPlan();
      if (!response.data.hasErrors) {
        toast.success(t("Reverted"));
      }
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        {workflowLockdownPlanList.map((workflow) => (
          <Card key={workflow.id} variant={"outlined"}>
            <CardContent>
              <Grid container>
                <Grid container justify="space-between">
                  <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-start" }}>
                    <Typography variant="body2">{workflow.description || t("None")}</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography variant="caption" color={isActive ? "primary" : "secondary"}>
                      {isActive ? t("Activated") : t("Disabled")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className={classes.spaceTop} justify="flex-start">
                  <Grid item>
                    <LoadingButton
                      disabled={isActive || loading}
                      pending={loading}
                      variant="contained"
                      color="primary"
                      onClick={() => startingLockDownPlan()}
                    >
                      {t("Lockdown")}
                    </LoadingButton>
                  </Grid>
                  <Grid item style={{ marginLeft: theme.spacing(2) }}>
                    <LoadingButton
                      disabled={isActive === false || loading}
                      pending={loading}
                      variant="outlined"
                      color="primary"
                      onClick={() => revertLockDownPlan()}
                    >
                      {t("Revert")}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </Grid>
    </Grid>
  );
}
