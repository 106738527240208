import { Method, AxiosResponse } from "axios"; //https://github.com/axios/axios/blob/master/index.d.ts
import { AccessLogResultType } from "./accessLog";
import { DeviceType, ObjectDetectionType } from "./devices";
import { SensorState, SensorType } from "./sensor";
import { ApprovalRequestStatus, ApprovalRequestType } from "./notifications";

export const axiosMethods = {
  get: <Method>"GET",
  post: <Method>"POST",
  put: <Method>"PUT",
  delete: <Method>"DELETE",
  patch: <Method>"PATCH",
};
export interface Response extends AxiosResponse {
  data: ResponseAPI;
}
export interface ResponseAPI {
  applicationEvents: ApplicationEvent[];
  payload: any;
  hasErrors: boolean;
  // "ApplicationPagedResponse" only for paged endpoints
  pageNumber?: number;
  pageSize?: number;
  firstPage?: string;
  lastPage?: string;
  totalPages?: number;
  totalRecords?: number;
  nextPage?: string;
  previousPage?: string;
}
export interface ApplicationEvent {
  type: EventTypesConstants;
  code: string;
  message: string;
  parameter: string;
}
export enum EventTypesConstants {
  ErrorEvent = "Error",
  InfoEvent = "Info",
}
export interface QueryFilter {
  pageNumber?: number;
  pageSize?: number;
  from?: string;
  to?: string;
  serialNumber?: string;
  descending?: boolean;
  orderBy?: string;
  emailStartsWith?: string;
  firstNameStartsWith?: string;
  lastNameStartsWith?: string;
  hasPortalAccess?: boolean;
  combinedSearch?: boolean; //TODO delete this
  combinedSearchStartsWith?: string;
  deviceType?: DeviceType[] | DeviceType;
  includeSiteInfo?: boolean;
  nameStartsWith?: string;
  includeAvatars?: boolean;
  reference?: string;
  like?: string;
  phoneNumber?: string;
  accessLogResultType?: AccessLogResultType;
  userEmail?: string;
  sensorType?: SensorType;
  sensorState?: SensorState;
  deviceSerialNumber?: string;
  sensorId?: string;
  eventType?: ObjectDetectionType;
  fromDate?: string;
  toDate?: string;
  isConnected?: boolean | undefined;
  includeImages?: boolean;
  activeOnly?: boolean;
  userStatus?: number | undefined;
  phoneNumberStartsWith?: string;
  userId?: string;
  excludeExpired?: boolean;
  status?: ApprovalRequestStatus;
  requestType?: ApprovalRequestType;
}
export interface ResponseHookSWR {
  isLoading: boolean;
  isValidating: boolean;
  error: Error;
  data?: ResponseAPI;
  hasErrors: boolean;
}

export const endPoints = {
  securableManagement: "securable-management",
  accountManagement: "account-management",
  licenseManagement: "license-management",
  countryManagement: "country-management",
  stateManagement: "state-management",
  userManagement: "user-management",
  siteManagement: "site-management",
  timezoneManagement: "timezone-management",
  zoneManagement: "zone-management",
  deviceManagement: "device-management",
  paymentManagement: "payment-management",
  groupManagement: "group-management",
  accessLogs: "access-logs",
  scheduleManagement: "schedule-management",
  credentialsManagement: "credentials-management",
  customerSupportManagement: "customersupport-manager",
  holidaysManagement: "holiday-management",
  integrationManagement: "integration-management",
  guestManagement: "guest-management",
  guestScheduleManagment: "guest-schedule-management",
  sensorManagement: "sensor-management",
  elevatorManagement: "elevator-management",
};
