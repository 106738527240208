import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../components/Utils/Custom/HelperCode";
import { axiosInstance } from "../../components/Utils/API/axiosConfig";
import i18n from "../../translation/i18n";
import moment from "moment";
import { AuthReducerModel, ScreenType } from "../../components/Utils/Models/store";
import { hasSomeAuthorization, isActionsAllowed } from "../../components/Auth/actionsCheck";
import { EScopesType } from "../../components/Utils/Models/auth";
import { UserAccessModel } from "../../components/Utils/Models/users";

const initialState: AuthReducerModel = {
  uid: "",
  token: "",
  scopes: [],
  userName: "",
  accountName: "",
  email: "",
  isAdmin: false,
  roleName: "",
  roleId: 0,
  roleRank: 0,
  language: "",
  accountId: 0,
  isEmailVerified: true,
  accountStatus: "",
  siteIds: [],
  siteNames: [],

  deviceType: ScreenType.Desktop,
  isIOS: false,
  hasTouchScreen: false,

  isSigningIn: false,
  isAuthenticated: false,
  signInErrorCode: "",

  isSigningOut: false,
  signOutErrorCode: "",

  isVerifying: false,

  isReauthenticateCredentialRequesting: false,
  isReauthenticateCredentialSuccess: false,
  reauthenticateCredentialErrorCode: "",

  isUpdatePasswordRequesting: false,
  isUpdatePasswordSuccess: false,
  updatePasswordErrorCode: "",

  isSignUpRequesting: false,
  isSignUpSuccess: false,
  signUpErrorCode: "",

  isVerifyEmailRequesting: false,
  isVerifyEmailSuccess: false,
  verifyEmailErrorCode: "",

  isVerifyPasswordResetCodeRequesting: false,
  isVerifyPasswordResetCodeSuccess: false,
  verifyPasswordResetCodeErrorCode: "",
  emailPasswordToReset: "",

  isConfirmPasswordResetRequesting: false,
  isConfirmPasswordResetSuccess: false,
  confirmPasswordResetErrorCode: "",

  isSendVerificationEmailRequesting: false,
  isSendVerificationEmailSuccess: false,
  sendVerificationEmailErrorCode: "",

  //authorizations
  hasPermissionAccessLogsRead: false,
  hasPermissionUserRead: false,
  hasPermissionUserWrite: false,
  hasPermissionRoleRead: false,
  hasPermissionGroupRead: false,
  hasPermissionGroupWrite: false,
  hasPermissionCredentialRead: false,
  hasPermissionCredentialWrite: false,
  hasPermissionDoorRead: false,
  hasPermissionDoorWrite: false,
  hasPermissionSiteRead: false,
  hasPermissionScheduleWrite: false,
  hasPermissionDoorRemoteAccessWrite: false,
  hasPermissionDoorSettingsWrite: false,
  hasPermissionAccountPaymentMethodRead: false,
  hasPermissionSettings: false,
  hasPermissionSupportArea: false,
  hasPermissionScheduleRead: false,
  hasPermissionSubscriptionWrite: false,
  hasPermissionAccountRead: false,
  hasPermissionAccountWrite: false,
  hasPermissionAccountPaymentMethodWrite: false,
  hasPermissionSiteWrite: false,
  hasPermissionTimezoneRead: false,
  hasPermissionZoneWrite: false,
  hasPermissionZoneRead: false,
  hasPermissionLockdownActivation: false,
  hasPermissionMobileRead: false,
  hasPermissionMobileWrite: false,
};
const updateTypeOfDevice = (state: AuthReducerModel) => {
  //https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
  try {
    let deviceType = ScreenType.Desktop;
    const UA = window.navigator.userAgent;
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    }
    const mQ = window.matchMedia && window.matchMedia("(pointer:coarse)");
    if (mQ && mQ.media === "(pointer:coarse)") {
      hasTouchScreen = !!mQ.matches;
    } else if ("orientation" in window) {
      // deprecated, but good fallback
      deviceType = ScreenType.Mobile;
      hasTouchScreen = true;
    }

    const isMobile =
      /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
      /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);

    const isIOS = /\b(iPad|iPhone|iPod)\b/i.test(UA);

    if (isMobile) {
      deviceType = ScreenType.Mobile;
      hasTouchScreen = true;
    }

    return {
      deviceType,
      isIOS,
      hasTouchScreen,
    };
  } catch (error) {
    return state;
  }
};
const updateAuthorization = (scopes: string[]) => {
  const hasPermissionAccessLogsRead = isActionsAllowed(scopes, [EScopesType.accesslogs_r]);
  const hasPermissionUserRead = isActionsAllowed(scopes, [EScopesType.user_r]);
  const hasPermissionUserWrite = isActionsAllowed(scopes, [EScopesType.user_w]);
  const hasPermissionRoleRead = isActionsAllowed(scopes, [EScopesType.role_r]);
  const hasPermissionGroupRead = isActionsAllowed(scopes, [EScopesType.group_r]);
  const hasPermissionGroupWrite = isActionsAllowed(scopes, [EScopesType.group_w]);
  const hasPermissionCredentialRead = isActionsAllowed(scopes, [EScopesType.credential_r]);
  const hasPermissionCredentialWrite = isActionsAllowed(scopes, [EScopesType.credential_w]);
  const hasPermissionDoorRead = isActionsAllowed(scopes, [EScopesType.device_r]);
  const hasPermissionDoorWrite = isActionsAllowed(scopes, [EScopesType.device_w]);
  const hasPermissionSiteRead = isActionsAllowed(scopes, [EScopesType.site_r]);
  const hasPermissionScheduleWrite = isActionsAllowed(scopes, [EScopesType.schedule_w]);
  const hasPermissionDoorRemoteAccessWrite = isActionsAllowed(scopes, [
    EScopesType.deviceremoteaccess_w,
  ]);
  const hasPermissionDoorSettingsWrite = isActionsAllowed(scopes, [EScopesType.devicesettings_w]);
  const hasPermissionAccountPaymentMethodRead = isActionsAllowed(scopes, [
    EScopesType.accountpaymethod_r,
  ]);
  const hasPermissionAccountPaymentMethodWrite = isActionsAllowed(scopes, [
    EScopesType.accountpaymethod_w,
  ]);
  const hasPermissionSiteWrite = isActionsAllowed(scopes, [EScopesType.site_w]);
  const hasPermissionTimezoneRead = isActionsAllowed(scopes, [EScopesType.timezone_r]);
  const hasPermissionZoneWrite = isActionsAllowed(scopes, [EScopesType.zone_w]);
  const hasPermissionZoneRead = isActionsAllowed(scopes, [EScopesType.zone_r]);
  const hasPermissionSettings = hasSomeAuthorization(
    [
      EScopesType.site_r,
      EScopesType.zone_r,
      EScopesType.group_r,
      EScopesType.role_r,
      EScopesType.support_r,
      EScopesType.schedule_r,
      EScopesType.account_r,
    ],
    scopes
  );
  const hasPermissionSupportArea = isActionsAllowed(scopes, [EScopesType.account_w]);
  const hasPermissionScheduleRead = isActionsAllowed(scopes, [EScopesType.schedule_r]);
  const hasPermissionSubscriptionWrite = false;
  /*isActionsAllowed(scopes, [
    EScopesType.accountsubscription_w,
  ]);*/
  const hasPermissionAccountRead = isActionsAllowed(scopes, [EScopesType.account_r]);
  const hasPermissionAccountWrite = isActionsAllowed(scopes, [EScopesType.account_w]);
  const hasPermissionLockdownActivation = isActionsAllowed(scopes, [EScopesType.lockdown_w]);
  const hasPermissionMobileRead = isActionsAllowed(scopes, [EScopesType.mobile_r]);
  const hasPermissionMobileWrite = isActionsAllowed(scopes, [EScopesType.mobile_w]);

  return {
    hasPermissionAccessLogsRead: hasPermissionAccessLogsRead,
    hasPermissionUserRead: hasPermissionUserRead,
    hasPermissionUserWrite: hasPermissionUserWrite,
    hasPermissionRoleRead: hasPermissionRoleRead,
    hasPermissionGroupRead: hasPermissionGroupRead,
    hasPermissionGroupWrite: hasPermissionGroupWrite,
    hasPermissionCredentialRead: hasPermissionCredentialRead,
    hasPermissionCredentialWrite: hasPermissionCredentialWrite,
    hasPermissionDoorRead: hasPermissionDoorRead,
    hasPermissionDoorWrite: hasPermissionDoorWrite,
    hasPermissionSiteRead: hasPermissionSiteRead,
    hasPermissionScheduleWrite: hasPermissionScheduleWrite,
    hasPermissionDoorRemoteAccessWrite: hasPermissionDoorRemoteAccessWrite,
    hasPermissionDoorSettingsWrite: hasPermissionDoorSettingsWrite,
    hasPermissionAccountPaymentMethodRead: hasPermissionAccountPaymentMethodRead,
    hasPermissionAccountPaymentMethodWrite: hasPermissionAccountPaymentMethodWrite,
    hasPermissionSiteWrite: hasPermissionSiteWrite,
    hasPermissionTimezoneRead: hasPermissionTimezoneRead,
    hasPermissionZoneWrite: hasPermissionZoneWrite,
    hasPermissionZoneRead: hasPermissionZoneRead,
    hasPermissionSettings: hasPermissionSettings,
    hasPermissionSupportArea: hasPermissionSupportArea,
    hasPermissionScheduleRead: hasPermissionScheduleRead,
    hasPermissionSubscriptionWrite: hasPermissionSubscriptionWrite,
    hasPermissionAccountRead: hasPermissionAccountRead,
    hasPermissionAccountWrite: hasPermissionAccountWrite,
    hasPermissionLockdownActivation: hasPermissionLockdownActivation,
    hasPermissionMobileRead: hasPermissionMobileRead,
    hasPermissionMobileWrite: hasPermissionMobileWrite,
  };
};
//////////////Reducer Slice//////////////////////
const updateUserAccess_Reducer = (state: AuthReducerModel, action: any) => {
  const userAccess: UserAccessModel = action.userAccess;
  return updateObject(state, {
    scopes: userAccess.scopes,
    isAdmin: userAccess.role.isAdmin,
    roleName: userAccess.role.displayName,
    roleId: userAccess.role.id,
    roleRank: userAccess.role.rank,
    siteIds: userAccess.sites.map((site) => site.id),
    siteNames: userAccess.sites.map((site) => site.name),
    hasCamerasFeature: userAccess.hasCameras,
    hasSensorsFeature: userAccess.hasSensors,
    hasMasNotificationFeature: userAccess.hasMasNotification,
    hasElevatorFeature: userAccess.hasElevator,
    hasPassRequiresApprovalSettingsFeature: userAccess.hasPassRequiresApprovalSettings,
    ...updateAuthorization(userAccess.scopes),
  } as AuthReducerModel);
};

const updateToken_Reducer = (state: AuthReducerModel, action: any) => {
  const refreshToken = action.refreshToken;
  // const userAccess: UserAccessModel = action.userAccess;
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${refreshToken.token}`;

  return updateObject(state, {
    isSigningIn: false,
    isAuthenticated: true,
    token: refreshToken.token,
    userName: refreshToken.claims.userName,
    email: refreshToken.claims.email,
    accountName: refreshToken.claims.accountName,
    language: refreshToken.claims.language,
    isEmailVerified: refreshToken.claims.email_verified,
    accountId: refreshToken.claims.accountId,
    accountStatus: refreshToken.claims.accountStatus,
    uid: refreshToken.claims.activeUserId,
  } as AuthReducerModel);
};

const signInRequest_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, { isSigningIn: true, signInErrorCode: "" } as AuthReducerModel);
};

const signInSuccess_Reducer = (state: AuthReducerModel, action: any) => {
  const decodedToken = action.decodedToken;
  // const userAccess: UserAccessModel = action.userAccess;

  axiosInstance.defaults.headers.common.Authorization = `Bearer ${decodedToken.token}`;

  let tempLanguage = decodedToken.claims.language;

  if (!decodedToken.claims.language) {
    if (localStorage.getItem("i18nextLng")) {
      const language: any = localStorage.getItem("i18nextLng");

      const parsedLanguage = language.split("-")[0];

      i18n.changeLanguage(parsedLanguage.toLowerCase() === "es" ? "es" : "en", () => {
        //
      });
      moment.locale(language as any);
      tempLanguage = language;
    } else {
      i18n.changeLanguage("en", () => {
        //
      });
      moment.locale("en");
      tempLanguage = "en";
    }
  } else {
    i18n.changeLanguage(decodedToken.claims.language as any, () => {
      //
    });
    moment.locale(decodedToken.claims.language);
  }

  return updateObject(state, {
    isSigningIn: false,
    isAuthenticated: true,
    token: decodedToken.token,
    userName: decodedToken.claims.userName,
    email: decodedToken.claims.email,
    accountName: decodedToken.claims.accountName,
    language: tempLanguage,
    isEmailVerified: decodedToken.claims.email_verified,
    accountId: decodedToken.claims.accountId,
    accountStatus: decodedToken.claims.accountStatus,
    uid: decodedToken.claims.activeUserId,
  } as AuthReducerModel);
};

const signInFailure_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isSigningIn: false,
    isAuthenticated: false,
    signInErrorCode: action.errorCode,
  } as AuthReducerModel);
};

const signOutRequest_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, { isSigningOut: true } as AuthReducerModel);
};

const signOutSuccess_Reducer = async (state: AuthReducerModel, action: any) => {
  axiosInstance.defaults.headers.common.Authorization = "";

  localStorage.removeItem("i18nextLng");
  return updateObject(state, initialState as AuthReducerModel);
};

const signOutFailure_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isSigningOut: false,
    signOutErrorCode: action.errorCode,
  } as AuthReducerModel);
};

const verifyTypeOfDevice_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, { ...updateTypeOfDevice(state) } as AuthReducerModel);
};

const verifySuccess_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, { isVerifying: false } as AuthReducerModel);
};

const reauthenticateCredentialRequest_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isReauthenticateCredentialRequesting: true,
    isReauthenticateCredentialSuccess: false,
    reauthenticateCredentialErrorCode: "",
  } as AuthReducerModel);
};

const reauthenticateCredentialSuccess_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isReauthenticateCredentialRequesting: false,
    isReauthenticateCredentialSuccess: true,
  } as AuthReducerModel);
};

const reauthenticateCredentialFailure_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isReauthenticateCredentialRequesting: false,
    isReauthenticateCredentialSuccess: false,
    reauthenticateCredentialErrorCode: action.errorCode,
  } as AuthReducerModel);
};

const updatePasswordRequest_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isUpdatePasswordRequesting: true,
    isUpdatePasswordSuccess: false,
    updatePasswordErrorCode: "",
  } as AuthReducerModel);
};

const updatePasswordSuccess_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isUpdatePasswordRequesting: false,
    isUpdatePasswordSuccess: true,
  } as AuthReducerModel);
};

const updatePasswordFailure_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isUpdatePasswordRequesting: false,
    isUpdatePasswordSuccess: false,
    updatePasswordErrorCode: action.errorCode,
  } as AuthReducerModel);
};

const signUpRequest_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isSignUpRequesting: true,
    isSignUpSuccess: false,
    signUpErrorCode: "",
  } as AuthReducerModel);
};

const signUpSuccess_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isSignUpRequesting: false,
    isSignUpSuccess: true,
  } as AuthReducerModel);
};

const signUpFailure_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isSignUpRequesting: false,
    isSignUpSuccess: false,
    signUpErrorCode: action.errorCode,
  } as AuthReducerModel);
};

const verifyEmailRequest_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isVerifyEmailRequesting: true,
    isVerifyEmailSuccess: false,
    verifyEmailErrorCode: "",
  } as AuthReducerModel);
};

const verifyEmailSuccess_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isVerifyEmailRequesting: false,
    isVerifyEmailSuccess: true,
  } as AuthReducerModel);
};

const verifyEmailFailure_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isVerifyEmailRequesting: false,
    isVerifyEmailSuccess: false,
    verifyEmailErrorCode: action.errorCode,
  } as AuthReducerModel);
};

const verifyPasswordResetCodeRequest_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isVerifyPasswordResetCodeRequesting: true,
    isVerifyPasswordResetCodeSuccess: false,
    verifyPasswordResetCodeErrorCode: "",
    emailPasswordToReset: "",
  } as AuthReducerModel);
};

const verifyPasswordResetCodeSuccess_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isVerifyPasswordResetCodeRequesting: false,
    isVerifyPasswordResetCodeSuccess: true,
    emailPasswordToReset: action.email,
  } as AuthReducerModel);
};

const verifyPasswordResetCodeFailure_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isVerifyPasswordResetCodeRequesting: false,
    isVerifyPasswordResetCodeSuccess: false,
    verifyPasswordResetCodeErrorCode: action.errorCode,
    emailPasswordToReset: "",
  } as AuthReducerModel);
};

const confirmPasswordResetRequest_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isConfirmPasswordResetRequesting: true,
    isConfirmPasswordResetSuccess: false,
    confirmPasswordResetErrorCode: "",
  } as AuthReducerModel);
};

const confirmPasswordResetSuccess_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isConfirmPasswordResetRequesting: false,
    isConfirmPasswordResetSuccess: true,
  } as AuthReducerModel);
};

const confirmPasswordResetFailure_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isConfirmPasswordResetRequesting: false,
    isConfirmPasswordResetSuccess: false,
    confirmPasswordResetErrorCode: action.errorCode,
  } as AuthReducerModel);
};

const sendVerificationEmailRequest_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isSendVerificationEmailRequesting: true,
    isSendVerificationEmailSuccess: false,
    sendVerificationEmailErrorCode: "",
  } as AuthReducerModel);
};

const sendVerificationEmailSuccess_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isSendVerificationEmailRequesting: false,
    isSendVerificationEmailSuccess: true,
  } as AuthReducerModel);
};

const sendVerificationEmailFailure_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    isSendVerificationEmailRequesting: false,
    isSendVerificationEmailSuccess: false,
    sendVerificationEmailErrorCode: action.errorCode,
  } as AuthReducerModel);
};

const changeLanguage_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, { language: action.locale } as AuthReducerModel);
};

const verifyRequest_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, { isVerifying: true } as AuthReducerModel);
};

const updateApprovalSettings_Reducer = (state: AuthReducerModel, action: any) => {
  return updateObject(state, {
    hasPassRequiresApprovalSettingsFeature: action.isApproved,
  } as AuthReducerModel);
};

/////REDUCER//////
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SIGNIN_REQUEST:
      return signInRequest_Reducer(state, action);
    case actionTypes.SIGNIN_SUCCESS:
      return signInSuccess_Reducer(state, action);
    case actionTypes.SIGNIN_FAILURE:
      return signInFailure_Reducer(state, action);
    case actionTypes.SIGNOUT_REQUEST:
      return signOutRequest_Reducer(state, action);
    case actionTypes.SIGNOUT_SUCCESS:
      return signOutSuccess_Reducer(state, action);
    case actionTypes.SIGNOUT_FAILURE:
      return signOutFailure_Reducer(state, action);
    case actionTypes.VERIFY_REQUEST:
      return verifyRequest_Reducer(state, action);
    case actionTypes.VERIFY_SUCCESS:
      return verifySuccess_Reducer(state, action);

    case actionTypes.REAUTHENTICATE_CREDENTIAL_REQUEST:
      return reauthenticateCredentialRequest_Reducer(state, action);
    case actionTypes.REAUTHENTICATE_CREDENTIAL_SUCCESS:
      return reauthenticateCredentialSuccess_Reducer(state, action);
    case actionTypes.REAUTHENTICATE_CREDENTIAL_FAILURE:
      return reauthenticateCredentialFailure_Reducer(state, action);

    case actionTypes.UPDATE_PASSWORD_REQUEST:
      return updatePasswordRequest_Reducer(state, action);
    case actionTypes.UPDATE_PASSWORD_SUCCESS:
      return updatePasswordSuccess_Reducer(state, action);
    case actionTypes.UPDATE_PASSWORD_FAILURE:
      return updatePasswordFailure_Reducer(state, action);

    case actionTypes.SIGNUP_REQUEST:
      return signUpRequest_Reducer(state, action);
    case actionTypes.SIGNUP_SUCCESS:
      return signUpSuccess_Reducer(state, action);
    case actionTypes.SIGNUP_FAILURE:
      return signUpFailure_Reducer(state, action);

    case actionTypes.VERIFY_EMAIL_REQUEST:
      return verifyEmailRequest_Reducer(state, action);
    case actionTypes.VERIFY_EMAIL_SUCCESS:
      return verifyEmailSuccess_Reducer(state, action);
    case actionTypes.VERIFY_EMAIL_FAILURE:
      return verifyEmailFailure_Reducer(state, action);

    case actionTypes.VERIFY_PASSWORD_RESETCODE_REQUEST:
      return verifyPasswordResetCodeRequest_Reducer(state, action);
    case actionTypes.VERIFY_PASSWORD_RESETCODE_SUCCESS:
      return verifyPasswordResetCodeSuccess_Reducer(state, action);
    case actionTypes.VERIFY_PASSWORD_RESETCODE_FAILURE:
      return verifyPasswordResetCodeFailure_Reducer(state, action);

    case actionTypes.CONFIRM_PASSWORD_RESET_REQUEST:
      return confirmPasswordResetRequest_Reducer(state, action);
    case actionTypes.CONFIRM_PASSWORD_RESET_SUCCESS:
      return confirmPasswordResetSuccess_Reducer(state, action);
    case actionTypes.CONFIRM_PASSWORD_RESET_FAILURE:
      return confirmPasswordResetFailure_Reducer(state, action);

    case actionTypes.SEND_VERIFICATION_EMAIL_REQUEST:
      return sendVerificationEmailRequest_Reducer(state, action);
    case actionTypes.SEND_VERIFICATION_EMAIL_SUCCESS:
      return sendVerificationEmailSuccess_Reducer(state, action);
    case actionTypes.SEND_VERIFICATION_EMAIL_FAILURE:
      return sendVerificationEmailFailure_Reducer(state, action);

    case actionTypes.UPDATE_TOKEN:
      return updateToken_Reducer(state, action);

    case actionTypes.CHANGE_LANGUAGE:
      return changeLanguage_Reducer(state, action);

    case actionTypes.VERIFY_TYPE_OF_DEVICE:
      return verifyTypeOfDevice_Reducer(state, action);

    case actionTypes.UPDATE_USER_ACCESS:
      return updateUserAccess_Reducer(state, action);

    case actionTypes.UPDATE_APPROVAL_SETTINGS:
      return updateApprovalSettings_Reducer(state, action);

    default:
      return state;
  }
};
export default reducer;
