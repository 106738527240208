const translationES = {
  //Users  /  Group
  Users: "Usuarios",
  AddUsers: "Agregar Usuarios",
  AddUser: "Agregar Usuario",
  ResetAntiPassback: "Restablecer Anti-Passback",
  TextAddUSerStep1: "Información del Usuario",
  TextAddUSerStep2: "Asignar a un Grupo (Opcional)",
  TakePhoto: "Tomar Foto",
  ErrorWebCamera: "Hay un error, intente habilitar los permisos de la cámara",
  AddPhoto: "Añadir Foto",
  Reset: "Reiniciar",
  Zoom: "Acercar",
  Rotate: "Rotar",
  Accept: "Aceptar",
  Assign: "Asignar",
  Created: "Creado",
  Create: "Crear",
  Assigned: "Asignado",
  Groups: "Grupos",
  AddGroup: "Agregar Grupo",
  Name: "Nombre",
  Description: "Descripción",
  TextAddGroupStep1: "Información del Grupo",
  TextAddGroupStep2: "Asignar Usuarios (Opcional)",
  UsersInTheGroup: "Usuarios del Grupo",
  PortalAccess: "Acceso al Portal",
  YES: "SI",
  NO: "NO",
  CannotDeleteUsersInTheGroup: "Hay usuarios asignados al grupo",
  GroupInfo: "Información del Grupo",
  ConfirmRemoveUserFromTheGroup: "Confirmar Eliminar al Usuario del Grupo",
  Removed: "Eliminado",
  AddAll: "Agregar Todos",
  UsersThereNotInTheGroup: "Usuarios que no están en el grupo",
  ConfirmAddAllUserToTheGroup: "Confirmar Añadir Todos los Usuarios al Grupo",
  AddedAll: "Agregado Todos",
  RemoveAll: "Eliminar Todos",
  RemovedAll: "Eliminó Todas",
  ConfirmRemoveAllUserFromTheGroup: "Confirmar Eliminar Todos los Usuarios del Grupo",
  UserInfo: "Información del Usuario",
  EditPhoto: "Editar Foto",

  // Sign Up Page
  SessionExpire: "Su sesión ha expirado",
  UnknownError: "El servidor encontró un error al procesar la solicitud.",
  ResourceNotFound: "Recurso No Encontrado",
  AccountWasCreated: "Su cuenta ha sido creada",
  YouWillReceiveAnEmail: "Usted recibirá un correo electrónico con más instrucciones en breve",
  PasswordNotMatch: "Contraseñas no coinciden",
  SignUp: "Registrarse",
  AccountName: "Nombre de Cuenta",
  FirstName: "Nombre",
  LastName: "Apellido",
  Email: "Email",
  Password: "Contraseña",
  ConfirmPassword: "Confirmar contraseña",
  Subscription: "Suscripción",
  SubscriptionDescription:
    "La suscripción seleccionada se basa en la cantidad de entrada de sus dispositivos. Siempre puede actualizar y cargar más dispositivos más adelante.",
  CVC: "CVC",
  // AgreeTerms: "By clicking “Next”, you agree to our",
  AgreeTerms: "Al hacer clic a continuación acepto",
  termsofservice: "los términos de servicio de Alocity",
  HaveAnAccount: "¿Ya tiene una cuenta?",
  TextSingUpStep1: "Información de Contacto",
  TextSingUpStep2: "Agregar Dispositivos",
  TextSingUpStep3: "Suscripción y Pago",
  OpenQRReader: "Abrir lector de QR",
  QRError: "No se encontraron dispositivos de entrada de vídeo",
  Serial: "Serial",
  AddMoreDevices: "Agregar más dispositivos",
  Remove: "Eliminar",
  Manually: "Manualmente",
  QRReader: "Lector de códigos QR",
  ScanDeviceQRCode: "Escanear el código QR del dispositivo",
  YouNeedToEnableYourCamera: "Es necesario habilitar la cámera para escanear el código QR",
  QRBoxInfo: "Ejemplo",
  EnterScanningQRCode: "Introduzca los números escandeando el código QR",
  EnterDevicesManually: "Introduzca los números manualmente",
  EnterDeviceNumbers: "Introduzca los números del dispositivo",
  SerialFieldBoxInfo: "Ejemplo 2",
  Add: "Agregar",
  DeviceAddedAlready: "El dispositivo ya fue añadido",
  DeviceAdded: "Dispositivo Agregado",
  AccountCreated: "Cuenta creada",
  year: "año",
  devices: "dispositivos",
  for: "para",
  BillingAddress: "Dirección de Facturación",
  CardNumber: "Número de Tarjeta",
  NameOnCard: "Nombre en la tarjeta",
  Expiration: "Expiración",
  Payment: "Pago",
  DeviceInvalid: "Dispositivo no válido",
  ZipCode: "Código postal",
  AddressLine: "Dirección",

  // Sign In Page
  SignIn: "Iniciar Sesión",
  Saved: "Salvado",
  Error: "Error",
  ForgotPassword: "Has olvidado tu contraseña?",
  SignInToYourAccount: "Iniciar Sesión",
  DontHaveAnAccount: "¿Necesitas una cuenta?",
  Yes: "Sí",
  No: "No",
  EmailNotVerified: "Correo electrónico no verificado",
  DoYouWantToResendEmail: "¿Quieres volver a enviar el correo electrónico",
  EmailSent: "Correo electrónico enviado",
  SignOut: "Cerrar Sesión",

  //layout
  Settings: "Configuración",

  // Forgot Password
  RecoveryPassword: "Recuperación de Contraseña",
  TroubleForgotPasswordText:
    "¿Olvidó la contraseña de su cuenta? Introduzca su dirección de correo electrónico y le enviaremos un enlace de recuperación.",
  Continue: "Continuar",
  ReturnToSignIn: "Volver a iniciar sesión",
  SuccessChangePassword: "Ha cambiado con éxito su contraseña",
  AccountRecoveryEmailSent: "Correo electrónico de recuperación de cuenta enviado",
  TextContentSuccessForgotPassword:
    "Revise su correo electrónico para obtener instrucciones para restablecer su contraseña. Si usted no ve este correo electrónico en su bandeja de entrada de 15 minutos, busque en su carpeta de correo basura. Si lo encuentras ahí, por favor márcalo como “No Basura”. ",
  ResetPasswordText: "Restablecer su contraseña",

  // Verify Email
  EmailVerified: "Su correo electrónico ha sido verificado",
  FailedEmailVerification: "No se pudo verificar el correo electrónico",
  YouCanSignInNewAccount: "Ahora puede iniciar sesión con su nueva cuenta",
  GoToSignInPage: "Ir a la página de inicio de sesión",

  // Validation Text
  Required: "Requerido",
  ShortText: "El texto es demasiado corto",
  LongText: "El texto es demasiado largo",
  InvalidEmail: "El correo electrónico no es válido",
  ShortPassword: "La contraseña es demasiado corta",
  InvalidPassword:
    "Las contraseñas deben contener al menos ocho caracteres, incluyendo al menos 1 letra mayúscula y 1 número",
  UnknowQR: "Unknown QR",
  ErrorConnectingWithPaymentProvider: "Falló la conexión con el proveedor de pagos",
  InvalidURL: "URL Incorrecta",

  // Profile
  Profile: "Perfil",
  Save: "Salvar",
  NewPassword: "Nueva contraseña",
  CurrentPassword: "Contraseña actual",

  // Subscriptions
  Subscriptions: "Suscripciones",
  BillingSubscriptions: "Facturación y Suscripciones",
  Transactions: "Transacciones",
  Upcoming: "Próximo",
  PaymentMethod: "Método de Pago",
  InvoiceNumber: "Número de Factura de Pago",
  Date: "Fecha",
  Dates: "Fechas",
  CreatedDate: "Fecha de Creación",
  DueDate: "Fecha de Vencimiento",
  Amount: "Cantidad",
  Paid: "Pagado",
  Unpaid: "Impagado",
  Void: "Vacío",
  ProductName: "Nombre del Producto",
  Comments: "Comentarios",
  Invoices: "Facturas",
  Type: "Tipo",
  NewCard: "Nueva Tarjeta",
  RenewalDate: "Fecha de Renovación",
  Draft: "Proyecto",
  Uncollectible: "Incobrable",
  Open: "Abrir",
  ConfirmCancelation: "Confirmar la Cancelación",
  AreYouSureWantContinues: "¿Seguro que quieres continuar?",
  ConfirmDeletion: "Confirmar la Eliminación",
  Default: "Predeterminado",
  Changed: "Cambiado",
  ConfirmChangeDefaultPaymentMethod: "Confirmar el cambio de método de pago predeterminado",
  ChangeDefaultPayment: "Cambio al Pago por Defecto",
  DefaultPayment: "Pago Predeterminado",

  // Table
  FirstPage: "Primera Página",
  PreviousPage: "Página Anterior",
  NextPage: "Página Siguiente",
  LastPage: "Última Página",
  New: "Nuevo",

  // Router
  VideoWall: "Mural de Vídeo",
  People: "Personas",
  Cameras: "Cámaras",
  Dashboard: "Panel de Control",
  Empty: "Vacío",

  // Settings
  GlobalSettings: "Configuración Global",
  SaveChanges: "Guardar los Cambios",
  Sites: "Sitios",
  Site: "Sitio",
  Zones: "Zonas",
  Zone: "Zona",
  TextSummary1GlobalSettings:
    "Sus sitios y zonas son ubicaciones en su empresa donde se colocarán sus dispositivos.",
  SampleDiagram: "Diagrama de Ejemplo",
  Roles: "Roles",
  TextSummary2GlobalSettings: "Administrar los roles en su cuenta",
  TimeZone: "Zona Horaria",
  CreateNewSite: "Agregar Nuevo Sitio",
  ZonesQty: "Cantidad de Zonas",
  CamerasAssigned: "Cámaras Asignadas",
  Actions: "Acciones",
  CreateANewSite: "Crear un Nuevo Sitio",
  SiteName: "Nombre del Sitio",
  EditSite: "Editar Sitio",
  DeleteSite: "Eliminar Sitio",
  AddZone: "Agregar Zona",
  ManageDevices: "Administrar Puertas",
  Deleted: "Eliminado",
  Delete: "Eliminar",
  Updated: "Actualizado",
  Update: "Actualizar",
  CreateZoneForThisSite: "Crear Zona para este Sitio",
  ZoneName: "Nombre de la Zona",
  selected: "seleccionado",
  AvailableCameras: "Las Cámaras Disponibles",
  SiteCameras: "Cámaras de Sitio",
  SubText_ManageSiteDevices:
    "Una puerta no se puede asignar a dos sitios o zonas diferentes. Si no puede encontrar un dispositivo de la lista, puede ser asignado a otro sitio. Asegúrese de seleccionar el sitio y no asignarlo para estar disponible.",
  AccessControl: "Control de Acceso",
  Sensors: "Sensores",
  More: "Más",
  Less: "Menos",
  EditZone: "Editar Zona",
  EditZones: "Editar Zonas",
  AddDevice: "Agregar Dispositivo",
  AddCamera: "Agregar Cámara",
  DeleteZone: "Eliminar Zona",
  Active: "Activo",
  Inactive: "Inactivo",
  Pending: "Pendiente",
  Suspended: "Suspendido",
  Card: "Tarjeta",
  CurrentPeriodEnd: "Final del Período",
  Total: "Total",
  Canceled: "Cancelado",
  Incomplete: "Incompleto",
  PastDue: "Atrasado",
  All: "Todo",
  Trialing: "Probando",
  Expired: "Expirado",
  CanceledAt: "Fecha Cancelada",
  StopRenewal: "Detener la Renovación",
  ChangeDeviceLocation: "Cambiar la Ubicación",
  DeleteDevice: "Eliminar Puerta",
  SelectASite: "Seleccionar un Sitio",
  SelectAZone: "Seleccionar una Zona",
  DevicesQty: "Cantidad de Puertas",
  PaymentDue: "Pago Debido",

  SiteSettingDescriptionText:
    "Este es el nombre de la ubicación y la zona horaria de la instalación, edificio, oficina o residencia.",
  ZoneSettingDescriptionText:
    "Agrupar puertas por zonas para configurar fácilmente horarios y análisis.",
  GroupSettingDescriptionText:
    "Establecer fácilmente los niveles de acceso para los usuarios por grupos.",
  Privileges: "Privilegios/Roles",
  PrivilegesSettingDescription: "Muestra los permisos para los roles.",

  // Cameras
  Location: "Ubicación",
  Editable: "Editable",
  EnterName: "Introducir el Nombre",
  LicensesBoxesSubtitle:
    "Las suscripciones seleccionadas se basan en la cantidad de entrada de sus dispositivos.",
  ListPaymentMethodSubtitle: "¿Qué tarjeta quieres usar?",
  UseANewCard: "Usar una tarjeta nueva",
  Added: "Agregado",
  ErrorToConnectPaymentProvider: "Falló la conexión con el proveedor de pagos",
  AddCameras: "Agregar Cámaras",
  ChangeDeviceName: "Cambiar el Nombre",
  Back: "Volver",
  CameraDetails: "Detalles de la Cámara",
  SharedStreams: "Transmisiones Compartidas",
  RebootCamera: "Reiniciar la Cámara",
  UnregisterCamera: "Eliminar la Cámara",
  Info: "Información",
  Events: "Eventos",
  History: "Historial",
  Model: "Modelo",
  Motion: "Movimiento",
  HumanMovement: "Movimiento Humano",
  FaceDetected: "Rostro Detectado",
  Packages: "Paquetes",
  Disconnected: "Desconectado",
  Camera: "Cámara",
  GoToLive: "Ir a vivo",
  PreviousDay: "Día Anterior",
  PersonDetected: "Persona Detectada",
  Vehicle: "Vehículo",
  AnyMovement: "Cualquier Movimiento",
  NoMasterConnected: "El dispositivo no está conectado al servidor para transmitir el vídeo",

  // Doors
  Doors: "Puertas",
  AddDoor: "Agregar Puerta",
  SecondaryZone: "Zona Secundaria",
  PrimaryZone: "Zona Principal",
  DoorState: "Estado de Puerta",
  LockState: "Estado de Bloqueo",
  Closed: "Cerrado",
  OpenVerified: "Abierto Verificado",
  OpenRequestToExit: "Abrir Solicitud de Salida",
  OpenForcedEntry: "Entrada Forzada Abierta",
  OpenTimedOut: "Abrir Expiró",
  NoSensor: "Sin Sensor",
  Locked: "Bloqueado",
  Unlocked: "Desbloqueado",
  Lockdown: "Modo Bloqueo",
  LockdownDoor: "Puerta en Modo Bloqueo",
  KeepOpen: "Mantener Puerta Abierta",
  UnlockedWithSchedule: "Desbloqueado con el Horario",
  UnlockedRequestToExit: "Desbloqueada para Salir",
  OpenDoor: "Abrir la Puerta",
  AddDevices: "Agregar Dispositivos",
  AccessControlInfo: "Información de Control de Acceso",
  Opened: "Abierto",
  Status: "Estado",
  Connected: "Conectado",
  Online: "En línea",
  Offline: "Desconectada",
  RemoteAccess: "Acceso Remoto",
  Reboot: "Reiniciar",
  DeviceDisconnected: "Equipo Desconectado",
  LastKnownState: "Último Estado Concido",
  Rebooted: "Reiniciado",
  ConfirmOperation: "Confirmar el Funcionamiento",
  NetworkSettings: "Configuración de Red",
  IPAddress: "Dirección IP",
  IP: "IP",
  DefaultGateway: "Puerta de Enlace Predeterminada",
  Gateway: "Enlace",
  Netmask: "Máscara de Red",
  Reseted: "Restablecer",
  DHCP: "DHCP",
  RequiredSelectOneZone: "Es necesario seleccionar al menos una zona",
  CannotBePrimaryAndSecondaryAtTheSameTime:
    "No puede pertenecer a una zona primaria y a una zona secundaria al mismo tiempo",
  NotChangeDetected: "No se detectaron cambios",
  Door: "Puerta",
  DoorInfo: "Información de la Puerta",

  // Notifications
  NotificationsDenied: "Las notificaciones son denegadas",
  NotificationNoSupported: "Este navegador no admite notificaciones de escritorio",
  Error_NotificationNoRegistrationTokenAvailable:
    "No hay ficha de registro disponible. Solicitar permiso para generar uno.",
  Error_NotificationAnErrorOcurred: "Se produjo un error al recuperar el token.",

  //AccessLogs
  AccessLogs: "Registros de Acceso",
  Time: "Tiempo",
  Result: "Resultado",
  AccessGranted: "Acceso Permitido",
  NotScheduled: "No Programada",
  UnknownUser: "Usuario Desconocido",
  Filter: "Filtro",
  Filters: "Filtros",
  Clear: "Limpiar",
  Done: "Hecho",
  From: "De",
  To: "A",
  Cancel: "Cancelar",
  and: "y",
  SerialNumber: "Número de Serie",
  Workflow: "Flujo de Trabajo",
  Face: "Rostro",
  Mobile: "Móvil",
  Web: "Red",

  //Schedules
  Schedules: "Horarios",
  AddSchedule: "Agregar Horarios",
  ScheduleInfo: "Información de Horarios",
  Automatic: "Automático",
  When: "Cuando",
  Resync: "Resincronización",
  TextAddScheduleStep1: "Información Básica",
  TextAddScheduleStep2: "Asignar CUANDO",
  TextAddScheduleStep3: "Asignar QUIÉN",
  TextAddScheduleStep4: "Asignar DONDE",
  NeverEnds: "Indeterminado",
  InvalidDate: "Fecha Inválida",
  InvalidTime: "Tiempo Inválido",
  Repeating: "Repitiendo",
  BasicInfo: "Información Básica",
  Sunday: "Domingo",
  Monday: "Lunes",
  Tuesday: "Martes",
  Wednesday: "Miercoles",
  Thursday: "Jueves",
  Friday: "Viernes",
  Saturday: "Sábado",
  StartTime: "Hora de Inicio",
  EndTime: "Hora de Finalización",
  MON: "LUN",
  TUE: "MAR",
  WED: "MIE",
  THU: "JUE",
  FRI: "VIE",
  SAT: "SAB",
  SUN: "DOM",
  Holidays: "Feriados",
  StartDate: "Fecha de Inicio",
  EndDate: "Fecha de Finalización",
  Next: "Próximo",
  AddWhen: "Agregar Cuando",
  Other: "Otros",
  ActiveOnHolidays: "Activo en días feriados",
  InvalidTimeComparison: "El tiempo tiene que ser mayor que el tiempo de inicio",
  InvalidDateComparison: "La fecha de fin debe ser superior a la fecha de inicio",
  ErrorTimeMustBeDifferent: "Los tiempos deben ser diferentes",
  WhoTitleText:
    "Seleccione para QUIEN el horario estará funcionando. La opción 'Usuarios' dará la posibilidad de asignar por usuario, la opción 'Grupos' dará la posibilidad de asignar por grupo de usuarios y el 'Horario Abierto Automático' dará la posibilidad de abrir sin un usuario.",
  AutomaticOpenSchedule: "Horario Abierto Automático",
  GroupsInTheSchedule: "Grupos en el Horario",
  ConfirmRemoveGroupFromTheSchedule: "Confirmar Eliminar el Grupo del Calendario",
  ConfirmAddAllGroupsToTheSchedule: "Confirmar agregar todos los grupos al programa",
  ConfirmRemoveAllGroupsFromTheSchedule: "Confirmar Eliminar Todos los Grupos del Programa",
  GroupsThereNotInTheSchedule: "Los grupos no están en el horario",
  Set: "Establecer",
  UnSet: "Sin Establecer",
  Activate: "Activar",
  Deactivate: "Desactivar",
  Activated: "Activado",
  Deactivated: "Desactivado",
  ActivatedAutomatic: "Activado automático horario abierto",
  DeactivatedAutomatic: "Desactivado automático horario abierto",
  RemovedAllGroups: "Eliminado Todos los Grupos",
  RemovedAllUsers: "Eliminado Todos los Usuarios",
  UsersInTheSchedule: "Usuarios en el Horario",
  ConfirmRemoveUserFromTheSchedule: "Confirmar Eliminar Usuario del Horario",
  ConfirmAddAllUserToTheSchedule: "Confirmar Agregar a Todos los Usuarios al Horario",
  ConfirmRemoveAllUsersFromTheSchedule: "Confirmar Eliminar Todos los Usuarios del Horario",
  UsersThereNotInTheSchedule: "Los Usuarios no están en el horario",
  RemovedAllSites: "Eliminado Todos los Sitios",
  RemovedAllZones: "Eliminado Todas las Zonas",
  RemovedAllDoors: "Eliminado Todas las Puertas",
  WhereTitleText:
    "Seleccione a DONDE el horario estará funcionando. La opción 'Sitios' dará la posibilidad de asignar por sitio, la opción 'Zonas' dará la posibilidad de asignar por grupo de puertas y la 'Puertas' dará la posibilidad de asignar por puerta.",
  Finalize: "Finalizar",
  DoorsInTheSite: "Puertas en el Sitio",
  SitesInTheSchedule: "Sitios en el Horario",
  AddSite: "Agregar Sitio",
  ConfirmRemoveSiteFromTheSchedule: "Confirmar Eliminar el Sitio del Horario",
  SitesThereNotInTheSchedule: "Los sitios no están en el horario",
  ConfirmAddAllSitesToTheSchedule: "Confirmar Agregar Todos los Sitios al Horario",
  ConfirmRemoveAllSitesFromTheSchedule: "Confirmar Eliminar Todos los Sitios del Horario",
  DoorsInTheZone: "Puertas en la Zona",
  ZonesInTheSchedule: "Zonas en el Horario",
  ConfirmRemoveZoneFromTheSchedule: "Confirmar la Eliminación de la Zona del Horario",
  ZonesThereNotInTheSchedule: "Zonas no están en el horario",
  ConfirmAddAllZonesToTheSchedule: "Confirmar Agregar Todas las Zonas al Horario",
  ConfirmRemoveAllZonesFromTheSchedule: "Confirmar Eliminar Todas las Zonas del Horario",
  DevicesInTheSchedule: "Puertas en el Horario",
  DevicesThereNotInTheSchedule: "Las Puertas que no están en el horario",
  ConfirmAddAllDevicesToTheSchedule: "Confirmar Agregar Todos las Puertas al Horario",
  ConfirmRemoveAllDevicesFromTheSchedule: "Confirmar Eliminar Todas las Puertas del Horario",
  ScheduleCreationFinalized: "Finalización de la creación del Horario",
  ThereAreNotUsersToRemove: "No hay usuarios para eliminar",
  ThereAreNotUsersToAdd: "No hay usuarios para agregar",
  ThereAreNotGroupsToAdd: "No hay grupos para agregar",
  ThereAreNotGroupsToRemove: "No hay grupos para eliminar",
  ThereAreNotSitesToAdd: "No hay sitios para agregar",
  ThereAreNotSitesToRemove: "No hay sitios para eliminar",
  ThereAreNotZonesToAdd: "No hay zonas para agregar",
  ThereAreNotZonesToRemove: "No hay zonas para eliminar",
  ThereAreNotDoorsToAdd: "No hay puertas para agregar",
  ThereAreNotDoorsToRemove: "No hay puertas para eliminar",
  Completed: "Completo",
  IncompleteMissingTheWhen: "Incompleto: Falta el Cuándo",
  IncompleteMissingTheWho: "Incompleto: Falta el Quién",
  IncompleteMissingTheWhere: "Incompleto: Falta el Dónde",
  NotDetectedAnyChange: "No se detectaron cambios",
  ShowOptions: "Mostrar Opciones",
  Warning: "Aviso",
  ChangeToAnotherOptionWillDeleteTheUserOrGroupSelected:
    "Cambiar a esta opción eliminará los usuarios o grupos agregados previamente. ¿Está seguro de que desea continuar?",

  // Zones
  PrimaryDevices: "Equipos Primarios",
  SecondaryDevices: "Equipos Secundarios",
  ZoneInfo: "Información de Zona",
  ThisZoneHasDevicesUpdate:
    "Esta zona tiene puertas. Cambia las puertas de la zona antes de mover la zona a otro sitio",
  ThisZoneHasDevicesDelete:
    "La zona tiene puertas. Cambia las puertas de la zona antes de eliminar la zona",
  ActionDenied: "Acción Denegada",
  EditZoneName: "Editar Nombre de Zona",

  /* Applications Response Errors*/

  // Account Errors
  error_account_not_created: "No se pudo crear la cuenta",
  error_account_read_error: "No se pudo recuperar la cuenta",
  error_account_delete_error: "No se pudo eliminar la cuenta",
  error_account_not_found: "Cuenta no encontrada",
  error_account_not_updated: "No se pudo actualizar la cuenta",
  error_account_name_required: "Se requiere el nombre de la cuenta",
  error_account_at_least_one_device_required: "Se requiere al menos un dispositivo",
  error_account_pending: "Cuenta pendiente",
  error_account_subscription_not_found: "Suscripción no encontrada",
  error_account_verification_link_not_sent: "No se pudo enviar el enlace de verificación",
  error_account_email_required: "Correo electrónico es obligatorio",
  error_account_settings_not_updated_in_devices:
    "Fallo al actualizar la configuración de la cuenta en el dispositivo",
  error_account_settings_not_found: "Configuración de la cuenta no encontrada",
  error_not_found_any_connected_device: "No se encontraron dispositivos conectados",

  // License Errors
  error_license_not_found: "Licencia no encontrada",
  error_license_read_error: "Fallo al recuperar la licencia",

  // Device Errors
  error_device_not_created: "No se pudo crear el dispositivo",
  error_device_read_error: "No se pudo recuperar el dispositivo",
  error_device_delete_error: "No se pudo eliminar el dispoitivo",
  error_device_invalid_serial_number: "Error, la serie es inválida",
  error_device_not_found: "Dispositivo no encontrado",
  error_device_provisioning_error: "Fallo a aprovisionar el dispositivo",
  error_device_not_available: "El dispositivo no está disponible",
  error_device_already_registered_in_same_account:
    "Error, el dispositivo ya está registrado en esta cuenta",
  error_device_assign_error: "Fallo al asignar el dispositivo",
  error_device_update_error: "Fallo al actualizar el dispositivo",
  error_device_reboot_error: "Error de reinicio del dispositivo", ///
  error_device_network_setting: "Error de configuración de red",
  error_device_network_setting_not_found: "Configuración de red no encontrada",
  error_device_notification: "Error de notificación", ////
  error_invalid_time_interval: "Intervalo de tiempo inválido",
  error_require_zones: "Se requieren zonas", ////
  lock_state_type_not_permitted: "Tipo de estado de bloqueo no permitido",
  error_lock_state_type: "Error de tipo de estado de bloqueo", ///
  error_setting_a_device: "Fallo al configurar el dispositivo", ///
  error_zones_not_in_same_site: "Las zonas no están en el mismo sitio", ///
  error_wrtc: "Error de WebRTC",
  error_account_Id_is_different_in_the_request:
    "Error, el ID de la cuenta es diferente en la solicitud",
  error_ble_key: "Error de clave de BLE",
  error_is_not_access_control_device: "Error, que no es un dispositivo de control de acceso",
  error_saving_access_control_settings: "Error al guardar la configuración del control de acceso",

  // Site Errors
  error_site_not_created: "No se pudo crear el sitio",
  error_site_read_error: "No se pudo recuperar el sitio",
  error_site_delete_error: "No se pudo eliminar el sitio",
  error_site_not_found: "No se encuentra el sitio",
  error_site_update_error: "No se pudo actualizar el sitio",
  error_site_has_zones: "Sitio tiene zonas", ///

  // Zone Errors
  error_zone_not_created: "Fallo al crear zona",
  error_zone_read_error: "No se pudo leer la zona",
  error_zone_update_error: "No se pudo actualizar la zona",
  error_zone_delete_error: "No se pudo eliminar la zona",
  error_zone_not_found: "Error, zona no encontrada",
  error_zone_assign_devices: "Error al asignar zona a dispositivos",
  error_equal_primary_and_secondary_zone: "Error, zonas primarias y secundarias son iguales",

  // Payment Error (and success messages)
  success_payment_get_customer_portal_url: "Dirreción del portal del cliente recuperada con éxito", ///,
  error_payment_get_customer_portal_url: "No se pudo recuperar el dirreción del portal del cliente",
  success_payment_webhook_invocation: "Invocado con éxito webhook de pago", ///
  error_payment_webhook_invocation: "Falloa al invocar el webhook de pago",
  success_payment_customer_created: "Pago al cliente creado con éxito", ///
  error_payment_customer_not_found: "Pago del cliente no encontrado", ////
  error_payment_setup_intent_error: "Error en la cocnfiguración de la intención de pago",
  error_payment_subscription_create_error: "No se pudo crear la suscripción de pago",
  error_payment_subscription_delete_error: "No se pudo eliminar la suscripción de pago",
  error_payment_countries_not_updated: "No se actualizaron los países",
  error_payment_public_key_not_found: "Clave pública no encontrada",
  error_payment_customer_not_created: "No se pudo crear el cliente", ///
  error_payment_customer_without_account: "No se econtró ningúna cuenta asociada con el cliente", ///
  error_payment_unknow_error:
    "Se ha producido un error desconocido durante el procesamiento de su pago", ///
  payment_succeeded: "El pago fue exitoso", ///
  incorrect_zip: "Código postal incorrecto",
  incorrect_cvc: "Cvc incorrecto",
  card_declined: "Su tarjeta fue rechazada",
  insufficient_funds: "No tiene fondos suficientes",
  expired_card: "Su tarjeta ha caducado",
  processing_error: "No se procesó el pago",
  incorrect_number: "El número proporcionado es incorrecto",
  error_payment_detach_payment_method: "error de separar método de pago", /// ??
  error_payment_read_error: "No se leyeron los pagos",
  error_payment_payment_method_not_found: "Método de pago no encontrado", /// invalid?
  error_payment_set_default_payment_method:
    "No se pudo establecer el método de pago predeterminado",
  error_payment_subscription_read_error: "No se pudo recuperar la suscripción", ///
  error_payment_subscription_not_found: "No se encontró suscripción", ///
  error_payment_invoice_read_error: "No se recuperó la factura", ////
  error_payment_upcoming_invoice_not_found: "Próxima factura no encontrada",

  // Phone Number
  error_phonenumber_invalid_format: "Formato de número de teléfono inválido",
  error_phonenumber_duplicated:
    "El número de teléfono proporcionado ya está siendo utilizado por otro usuario",
  error_require_phone_number: "Número de telefono requerido",

  // System Error (and success messages)
  succeed_system_operation: "Funcionamiento del sistema realizado con éxito", ////
  failed_system_operation: "No se pudo realizar la operación",
  error_system_disable_email_and_sms_notifications:
    "Fallo al desactivar las notificaciones de correo electrónico y sms",
  error_invalid_value: "Valor Inválido",

  // Password Reset Errors
  failed_passwordreset_operation: "Fallo al restablecer la contraseña",

  // Account Switch Errors
  failed_accountswitch_operation: "Fallo en la operación de cambio de cuenta",

  // Token Errors
  error_token_invalid: "Token inválida",
  error_token_not_found: "Token no encontrada",
  error_token_inactive: "Token inactivo",
  error_token_expired: "Token expirado",
  error_token_update_failed: "Fallo al actualizar el token",
  error_token_delete_failed: "No se pudo eliminar el token",
  info_token_delete: "Eliminar información de token",
  error_token_create_failed: "Fallo al crear el token",
  info_token_create: "Crear información de token",

  // User Errors
  error_user_not_created: "Fallo al crear usuario",
  error_user_not_deleted: "No se pudo eliminar el usuario",
  error_user_not_found: "Usuario no encontrado",
  error_user_error_deleting_account_owner: "No puede eliminar al propietario de la cuenta",
  error_user_not_read: "No se pudo recuperar el usuario",
  error_user_not_updated: "No se pudo actualizar el usuario",
  error_user_error_updating_account_owner: "Fallo al actualizar el propietario de la cuenta", ///?
  error_user_confirm_email_verification:
    "Error en la verificación del correo electrónico de confirmación",
  error_user_change_password: "Fallo al camibar la contraseña", ///
  error_user_verify_password_reset_code:
    "No se pudo verificar el código de restablecimiento de contraseña", ///
  error_user_get_accounts: "Fallo al recuperar cuentas de usuario",
  error_user_accounts_not_found: "Cuentas no encontradas",
  error_user_email_already_used_on_same_account: "Correo electrónico ya en uso en la misma cuenta",
  error_user_invalid_settings: "Configuración de usuario inválido",
  error_user_external_system_id_not_created: "Fallo al crear ID de sistema externo",
  error_user_external_system_id_not_deleted: "Fallo al eliminar el ID del sistema externo",
  error_user_invalid_external_system_id: "ID de sistema externo inválido",
  error_require_role_id: "Se requiere ID de rol",
  error_invalid_role_id: "ID de rol inválido",
  error_credential: "Error de credenciales", ///
  error_face_not_found: "Rostro no encontrado",

  // Locale Errors
  error_locale_not_found: "Localización no encontrada",
  error_locale_iso2_required: "Se requiere locale iso2",

  // Role Errors
  error_role_not_found: "Rol no encontrado",
  error_reading_role: "No se pudo recuperar el rol",
  error_creating_role: "No puedo crear rol",
  error_reading_scope: "Fallo al recuperar el alcance",
  error_deleting_role: "No se eliminó el rol",
  error_updating_role: "No se pudo actualizar el rol",

  // Identity Errors
  error_identity_user_not_deleted: "No se pudo eliminar la identidad del usuario", ///
  error_identity_user_already_exist: "La identidad del usuario ya existe", ///
  error_identity_user_not_created: "No se pudo crear la identidad del usuario", ///
  error_identity_user_not_updated: "No se pudo actualizar la identidad del usuario", ///
  error_identity_not_generated_link: "No se pudo generar el enlace de identidad", ///
  error_identity_credential_too_old_login_again:
    "Credenciales antiguas. Por favor regístre de nuevo", ///
  error_identity_unknow_response: "Error de identidad desconocido", ///
  error_identity_wrong_password: "Contraseña de identidad incorrecta", ///

  // Country Errors
  error_country_read_error: "No se pudo recuperar el país",

  // State Errors
  error_state_read_error: "No se pudo recuperar el estado",

  // Timezone Errors
  error_timezone_not_found: "Zona horario no encontrada",
  error_timezone_read_error: "No se pudo recuperar la zona horario",

  // QrCode
  error_qr_code_create_error: "Fallo al crear código QR",

  // Notifications Error (and messages)
  error_notification_required_parameter: "Falta el parámetro requerido",
  error_notification_error_sending: "No se envió la notificación",
  notification_person_detected: "Persona detectada",
  notification_vehicle_detected: "Vehículo detectado",
  notification_motion_detected: "Movimiento detectado",
  notification_status_connected: "Conectado",
  notification_status_disconnected: "Desconectado",
  notification_status_updating: "Actualizando",
  notification_lock_state: "Estado de bloqueo cambiado",
  notification_door_state: "Estado de la puerta cambiado",
  notification_face_enrollment_result: "Resultados de inscripción facial recibidos",
  notification_wrtc_result: "Recibido el resultado de WebRTC",
  notification_access_log: "Registro de acceso recibido",
  notification_firmware_upgrade: "Actualización de firmware recibida",
  notification_door_bell_ring: "Timbre de Puerta",
  // Schedule Errors
  error_schedule_processing: "Error de procesamiento de horario",
  error_unknown_schedule_type: "Tipo de horario desconocido",
  error_schedule_not_found: "Horario no encontrado",
  error_schedule_has_duplicated_entities: "Horario tiene duplicado entidades",
  error_schedule_is_null: "El horario es nulo",
  error_schedule_has_no_cronschedules: "Horario no tiene cronogramas",
  error_reading_schedule_info: "No se pudo recuperar la información del horario",
  error_group_required: "Grupo requerido",
  error_user_required: "Usuario requerido",
  error_device_required: "Dispositivo requerido",
  error_zone_required: "Zona requerida",
  error_site_required: "Sitio requerido",

  // Cron Schedule Errors
  error_cron_has_no_duration: "Cron no tiene duración",
  error_cron_invalid_interval: "Intervalo cron inválido",
  error_cron_invalid_expression: "Expresión cron inválido",

  // Group Errors
  error_group_not_created: "No se pudo crear el grupo",
  error_group_not_read: "No se pudo recuperar el grupo",
  error_group_not_updated: "No se pudo actualizar el grupo",
  error_group_not_deleted: "No se pudo eliminar el grupo",
  error_group_not_found: "Grupo no encontrado",

  // Card Errors
  error_card_not_created: "No se pudo crear la tarjeta",
  error_card_not_read: "No se pudo recuperar la tarjeta",
  error_card_not_updated: "No se pudo actualizar la tarjeta",
  error_card_not_deleted: "No se pudo eliminar la tarjeta",
  error_card_not_found: "Tarjeta no encontrada",

  // Access Log Errors
  error_access_log_not_created: "No se pudo crear el registro de acceso",
  error_access_log_read: "No se pudo recuperar el registro de acceso",
  error_invalid_access_result: "Resultado de acceso no válido",

  // Cloud Key Errors
  error_cloud_key_not_found: "Clave de nube no encontrada",
  error_cloud_key_expired: "Su clave de nube ha expirado",
  error_cloud_key_out_of_time_rage: "Clave de nube está fuera del rango de tiempo",
  error_invalid_time_scope: "Alcance temporal inválido",
  error_creating_cloud_key: "No se pudo crear la clave de nube",
  error_first_name_and_last_name_required: "Se requieren nombre y apellido",
  error_open_the_door: "No abrió la puerta",
  error_reading_cloud_keys: "No se pudo recuperar las claves de nube",
  error_updating_cloud_key: "No se pudo actualizar las claves de nube",

  // Face
  error_deleting_face: "No se pudo eliminar la cara",
  error_not_found: "Error, cara no encontrada",
  error_low_quality: "Error, calidad de la imagen baja",
  error_image_notsaved: "Error, imagen no guardada",
  error_face_does_not_match: "Error, la cara no coincide",
  error_user_has_3d_face:
    "Usuario con rostro ya registrado. Elimínelo antes de enviar la invitación a Selfie.",

  // Audit Traiol
  error_create_audittrail: "No se pudo crear un registro de auditoría",

  error_empty_identification_workflow_list: "Error, la lista de identificaciones esta vacía",

  None: "Ninguno",
  Unknown: "Desconocido",
  TimedOut: "Expiró",
  Cancelled: "Cancelado",
  FaceAlreadyEnrolled: "Rostro ya se ha registrado",
  CardAlreadyEnrolled: "Tarjeta ya se ha registrado",

  NoFacesInImage: "No se detectó ningúnas caras en la imagen",
  TooManyFacesInImage: "Se detectaron demasiadas caras en la imagen",
  FaceTooClose: "Rostro está demasiado cerca",
  FaceTooFar: "La cara está demasiado lejos",
  FaceNotStraightForward: "Por favor, trae tu cara hacia adelante y mira hacia adelante",

  // Firebase Authentication Errors
  auth_invalid_email: "Correo electrónico inválido",
  error_email_invalid_format: "Formato de correo electrónico inválido",
  auth_wrong_password: "Contraseña incorrecta",
  // auth_user_not_found: "The user not corresponding the link. This may have happened if the user was deleted between when the link was issued and when this page was called",
  auth_user_not_found:
    "Usuario no encontrado. Esto puede ocurrir si el usuario se elimina entre el momento en que se emitió el enlace y la página fue llamada.",
  auth_user_disabled: "El usuario ha sido deshabilitado", //
  auth_weak_password: "La neuva contraseña no es lo suficientemente furete",
  auth_invalid_action_code: "Enlace expirado o mal formado",
  auth_expired_action_code: "Enlace expirado",
  auth_user_mismatch:
    "Las credenciales suministradas no corresponden al usuario previamente firmado en",
  auth_invalid_credential: "Credenciales inválidos",
  auth_reauthenticate_user_not_found:
    "Las credenciales suministradas no corresponden a ningún usuario existente",
  auth_invalid_verification_code: "Código de verificación inválido",
  auth_invalid_verification_id: "Identificación de verificación inválida",
  auth_requires_recent_login:
    "El último tiempo de inicio de sesión del usuario no cumple el umbral de seguridad",
  auth_web_storage_unsupported: "El navegador no admite almacenamiento de red",
  auth_too_many_requests:
    "Solicitud bloqueada debido a una actividad inusual. Inténtelo de nuevo más tarde",
  auth_operation_not_allowed:
    "El proveedor suministrado no está habilitado. Por favor, actívalo antes de proceder", //
  auth_email_already_in_use: "El correo electrónico ya está en uso",
  auth_network_request_failed:
    "Se ha producido un error de red (como tiempo de espera, conexión interrumpida o host inalcanzable)",

  //Users  /  User Details
  Admin: "Admin",
  User: "Usuario",
  AddCredential: "Agregar Credencial",
  PhoneNumber: "Número de Teléfono",
  Role: "Rol",
  TypesOfCredentials: "Tipo de Credencial",
  FacilityCode: "Código de la Instalación",
  CardId: "ID de Tarjeta",
  Edit: "Editar",
  Capture: "Capturar",
  Submit: "Enviar",
  Retake: "Recapturar",
  AreYouSureYouWantToContinue: "¿Seguro que quieres continuar?",
  AddCard: "Agregar Tarjeta",
  EditCard: "Editar Tarjeta",

  GroupsWithTheUser: "Grupos con el usuario",
  ConfirmAddTheUserToAllGroups: "Confirmar agregar el usuario a todos los grupos",
  ConfirmRemoveTheUserFromAllGroups: "Confirmar eliminar el usuario de todos los grupos",
  ConfirmRemoveTheUserFromTheGroup: "Confirmar la eliminación del usuario del grupo",
  TheNumbersOfUsersThatTheGroupContain: "Número de usuarios que contiene el grupo",
  GroupsThatNotContainTheUser: "Grupos que no contienen al usuario",
  ImageSent: "Imagen enviada. Analizando la imagen",
  ImageAddedForTheUser: "Imagen agregada para el usuario",
  FailAddImageForTheUser: "No se pudo agregar la imagen para el usuario",
  becauseOf: "causa de",
  //cloudkey
  DoorWithAccess: "Puerta Con Acceso",
  UseOneTime: "Usar Una Sola Vez",
  ValidFrom: "Válido de",
  ExpireOn: "Vence en",
  SentBy: "Enviado por",
  Phone: "Teléfono",
  CannotDeleteInactiveCloudKey: "No se puede eliminar la clave de la nube inactiva",
  CloudKey: "Clave de Nube",
  ExternalPersonInfo: "Información personal externa",
  SendInvitationByEmail: "Enviar invitación por correo electrónico",
  SendInvitationByText: "Enviar invitación por texto",
  SendInvitationBy: "Enviar invitación por",
  UseOneTimeOnly: "Usar una sola vez",
  InvalidPhoneNumber: "Formato del número de teléfono no válido",
  AccessTo: "Acceso a",
  Inactivated: "Inactivado",
  Inactivate: "Inactivar",
  ConfirmInactivation: "Confirmar la Inactivación",
  ResyncDevice: "Resincronizar Dispositivo",
  DoorOpened: "Puerta Abierta",
  OpenAgain: "Abrir de Neuvo",
  CloudKeyAccess: "Acceso de Clave de Nube",
  InvitationType: "Tipo de Invitación",
  Link: "Enlace",
  QRCode: "Código QR",
  QrCode: "Código QR",

  TheUserIsNotAssociatedWithASchedule:
    "El usuario no está asociado a ningún horario y por esa razón no tiene acceso a ninguna puerta.",
  //roles
  AddRole: "Agregar Rol",
  TotalPermissions: "Permisos Totales",
  RoleInfo: "Información de Rol",
  TextAddRoleStep1: "Información Básica",
  TextAddRoleStep2: "Permisos",
  Preset: "Valor Predefinido",
  AddPermissionTitleText: "Seleccione los permisos para el nuevo rol",

  //sites
  TotalDevices: "Total de Dispositivos",
  TotalZones: "Zones Totales",
  ThisSiteHasZones: "El sitio tiene zonas, así que borre las zonas primero.",
  DevicesInTheZone: "Dispositivos en la Zona",
  SiteInfo: "Información del Sitio",
  AddNewZone: "Agregar Nueva Zona",
  PayAndComplete: "Pagar y Compmletar",

  //500 page error
  SystemError: "Lo siento, error inesperado",
  SystemErrorSubtitle:
    "No estamos exactamente seguros de los que pasó, pero nuestro equipo ha sido notificado.",
  OurTeamNotified:
    "Nuestro equipo ha sido notificado. Si desea ayudar, díganos lo que pasó a continuación.",

  Language: "Idioma",
  English: "Inglés",
  Spanish: "Español",

  // Page Not Found
  PageNotFound: "404",
  PageNotFoundSubtitle: "Lo sentimos, no pudimos encontrar la página que estabas buscando.",
  GoDashboard: "Volver al Panel Principal",

  //SupportArea
  Firmware: "Firmware",
  SupportArea: "Área de apoyo",
  AccountAreaDescriptionText: "Controlar y actuar en diferentes áreas.",
  FirmwareDescriptionText: "Actualice el firmware de los dispositivos.",
  UpgradeAllAccounts: "Actualizar todas las cuentas",
  UpgradePerAccount: "Actualizar por cuenta",
  UpgradePerDevices: "Actualizar por dispositivo",
  ConfirmUpgrade: "Confirmar actualización",
  ConfirmUpgradeAllAccountsText:
    "Estás actualizando todos los dispositivos de todas las cuentas. ¿Estás seguro de que quieres continuar ?.",
  Upgrade: "Actualizar",
  Accounts: "Cuentas",
  Account: "Cuenta",
  Confirm: "Confirmar",
  Devices: "Dispositivos",
  IdentificationWorkflow: "Flujo de Trabajo de Identificación",
  ExternalCard: "Tarjeta Externa",
  Clean: "Limpiar",
  Options: "Opciones",
  NewIdentificationWorkflow: "Nuevo flujo de trabajo de identificación",
  RelayState: "Estado del Relé",
  InternalRelay: "Internal Relé",
  IORelay1: "E/S Relé 1",
  IORelay2: "E/S Relé 2",
  LockReleaseTime: "Tiempo Liberar Cerradura",
  DoorOpenTimeout: "Tiempo Puerta Abierta",
  Internal: "Interno",
  External: "Externo",
  DoorContactPosition: "Posición de Contacto Puerta",
  DoorContactType: "Tipo de Contacto Puerta",
  NormallyOpen: "Normalmente Abierto",
  NormallyClosed: "Normalmente Cerrado",
  ExitButtonContactPosition: "Posición Contacto Botón Salida",
  ExitButtonContactType: "Tipo Contacto Botón Salida",
  WiegandInputEnabled: "Entrada Wiegand Habilitada",
  WiegandOutputEnabled: "Salida Wiegand Habilitada",
  WiegandFormat: "Formato Wiegand",

  AddNewPermission: "Agregar Nuevo Permiso",
  AddNew: "Agregar Nuevo",
  ThisRoleOnlyHas1ReadPermission: "Este rol solo tiene 1 permiso de lectura",
  ConfirmRemove: "Confirmar Eliminación",
  Permissions: "Permisos",
  ThisRoleHasAllPermissions: "Este rol ya tiene todos los permisos.",
  Close: "Cerrar",

  //Scopes - Source Alocity Roles and Scopes spreadsheet
  accesslogs_r: "Leer Registros de Acceso",
  account_r: "Leer Información Cuenta",
  account_w: "Actualizar Información Cuenta",
  account_all: "Leer o Actualizar Información Cuenta",
  accountall_r: "Leer o Actualizar Información Cuenta",
  accountsettings_r: "Leer Configuración Cuenta",
  accountsettings_w: "Actualizar Configuración Cuenta",
  accountpaymethod_r: "Leer Métodos de Pago Cuenta",
  accountpaymethod_w: "Actualizar Métodos de Pago Cuenta",
  accountsubscription_r: "Leer Información Suscripción Cuenta",
  accountsubscription_w: "Actualizar Suscripción Cuenta",
  card_r: "Leer Tarjetas",
  card_w: "Actualizar Tarjetas",
  cardall_r: "Leer Todas las Tarjetas",
  face_r: "Leer Rostros Enrolados",
  face_w: "Actualizar Rostros Enrolados",
  cloudkey_r: "Leer Claves de Nube",
  cloudkey_w: "Actualizar Claves de Nube",
  cloudkeyall_r: "Leer Todas Claves de Nube",
  support_r: "Leer Datos de Soporte",
  device_r: "Leer Equipos",
  device_w: "Actualizar Equipos",
  devicesettings_w: "Leer Configuración Equipos",
  deviceweb_w: "Iniciar Soporte Equipos",
  deviceall_r: "Leer Todos Equipos",
  group_r: "Leer Grupos",
  group_w: "Actualizar Grupos",
  schedule_r: "Leer Horarios",
  schedule_w: "Actualizar Horarios",
  credential_r: "Leer Credentiales",
  role_r: "Leer Roles",
  role_w: "Actualizar Roles",
  site_r: "Leer Sitios",
  site_w: "Actualizar Sitios",
  timezone_r: "Leer Zonas Horarias ",
  user_r: "Leer Usuarios",
  user_w: "Actualizar Usuarios",
  usersettings_r: "Leer Configuración Usuario",
  usersettings_w: "Actualizar Configuración Usuario",
  zone_r: "Leer Zonas",
  zone_w: "Actualizar Zonas",

  accesslogs_r_description: "Este permiso permite al usuario leer los registros de acceso.",
  account_r_description: "Este permiso permite al usuario leer la información de la cuenta.",
  account_w_description: "Este permiso permite al usuario cambiar la información de la cuenta.",
  account_all_description: "Este permiso permite al usuario leer todas las cuentas.",
  accountall_r_description: "Este permiso permite a un usuario leer todas las cuentas.",
  accountsettings_r_description:
    "Este permiso permite al usuario leer la configuraciones de la cuenta.",
  accountsettings_w_description:
    "Este permiso permite al usuario actualizar la configuraciones de la cuenta.",
  accountpaymethod_r_description: "Este permiso permite al usuario leer los métodos de pagos.",
  accountpaymethod_w_description:
    "Este permiso permite al usuario actualizar los métodos de pagos.",
  accountsubscription_r_description:
    "Este permiso permite al usuario leer la información de las subcripciones de la cuenta.",
  accountsubscription_w_description:
    "Este permiso permite al usuario actualizar las subcripciones de la cuenta.",
  card_r_description: "Este permiso permite al usuario leer la información de la tarjeta.",
  card_w_description: "Este permiso permite al usuario actualizar la información de la tarjetas.",
  cardall_r_description: "Este permiso permite al usuario leer todas las tarjetas.",
  face_r_description: "Este permiso permite al usuario ver los rostros enrolados.",
  face_w_description: "Este permiso permite al usuario actualizar los rostros enrolados.",
  cloudkey_r_description: "Este permiso permite al usuario leer los Cloud Keys.",
  cloudkey_w_description: "Este permiso permite al usuario actualizar los Cloud Keys.",
  cloudkeyall_r_description:
    "Este permiso permite al usuario leer todos los Cloud Keys para una organización.",
  support_r_description: "Este permiso permite al usuario leer datos de Atención a Cliente.",
  device_r_description: "Este permiso permite al usuario leer los datos de los equipos.",
  device_w_description: "Este permiso permite al usuario actualizar la información de los equipos.",
  devicesettings_w_description:
    "Este permiso permite al usuario actualizar la configuración de los equipos.",
  deviceweb_w_description:
    "Este permiso permite al usuario iniciar los pedidos de Atención a Cliente.",
  deviceall_r_description:
    "Este permiso permite al usuario ver la información de todos los equipos de una cuenta.",
  group_r_description: "Este permiso permite al usuario leer los grupos.",
  group_w_description: "Este permiso permite al usuario actualizar los grupos.",
  schedule_r_description: "Este permiso permite al usuario leer los horarios programados.",
  schedule_w_description: "Este permiso permite al usuario actualizar los horarios programados.",
  credential_r_description: "Este permiso permite al usuario leer las credenciales.",
  role_r_description: "Este permiso permite al usuario leer los roles.",
  role_w_description: "Este permiso permite al usuario actualizar los roles.",
  site_r_description: "Este permiso permite al usuario leer la información de los sitios.",
  site_w_description: "Este permiso permite al usuario actualizar la información de los sitios.",
  timezone_r_description: "Este permiso permite al usuario leer las zonas horarios.",
  user_r_description: "Este permiso permite al usuario leer la información de los usuarios.",
  user_w_description: "Este permiso permite al usuario actualizar la información de los usuarios.",
  usersettings_r_description:
    "Este permiso permite al usuario leer la configuraciones de los usuarios.",
  usersettings_w_description:
    "Este permiso permite al usuario actualizar la configuraciones de los usuarios.",
  zone_r_description: "Este permiso permite al usuario leer la información de las zonas.",
  zone_w_description: "Este permiso permite al usuario actualizar la información de las zonas",

  ForbiddenAccess: "Acceso Prohibido",
  ForbiddenAccessSubtitle: "Lo sentimos, no tiene acceso a esta página.",
  ForbiddenAccessSubtitle2: "Si siente que le falta algo que debería estar aquí, contáctenos.",

  ConfirmRemoveZone: "Confirmar la Eliminación de la Zona",

  error_card_duplicated: "Error, tarjeta repetida",
  Credentials: "Credenciales",
  Device: "Dispositivo",
  Text: "Texto",
  Valid: "Válido",

  Country: "País",
  City: "Ciudad",
  State: "Estado",
  Expires: "Expira",
  Duplicated: "Duplicated",
  Download: "Descargar",
  Expire: "Expira",
  MacAddress: "Dirección MAC",
  DeviceSetup: "Configuración de dispositivo",
  DeviceNumbersInvalid: "Los numeros del device son inválidos",

  parameter_missing: "Error, faltan parámetros", //error by Stripe when there are missing parameters
  Review: "Revisar",
  Select: "Seleccionar",
  error_duplicated_work_flow_secuence: "Error, secuencia de flujo de trabajo duplicada",
  ChargeToDefaultCard: "La cantidad total se cargará a la tarjeta predeterminada..",
  Success: "Satisfactorio",
  Change: "Cambiar",
  AddNewCloudkey: "Agregar nueva clave de nube",
  AddCloudkey: "Agregar Clave de Nube",
  List: "Lista",
  CaptureTheFace: "Capturar el rostro",
  EnableCameraAccess: "Por favor, habilite la cámara",
  ThisUserIsNotScheduledToUseAnyDoors: "Este usuario no está programado para usar ninguna puerta",
  AddUsingADevice: "Agregar usando un dispositivo",
  AddUsingWebCam: "Agregar usando la cámara web",
  CaptureTheFaceByDevice: "Capturar el rostro usando un dispositivo",
  ThisUserIsNotScheduledToUseAnyDoorsORThereAreNotConnectedDoors:
    "Este usuario no está programado para usar ninguna puerta o no hay ninguna puerta conectada",
  StandInFrontOfDevice: "Pararse frente al dispositivo",
  PublishUnknownUsers: "Publicar Imagen Usuarios Desconocidos",
  ON: "ON",
  OFF: "OFF",
  ControlTheAutomaticOpenSchedule: "Controlar el horario de apertura automática",
  error_user_without_schedule: "Este usuario no está programado para usar ninguna puerta",
  error_not_connected_device_found: "No hay ninguna puerta conectada",
  Local: "Local",
  Details: "Detalles",
  Recommended: "Recomendado",
  EditName: "Editar Nombre",
  ThisGroupHasUsers: "El grupo tiene usuarios, así que remueva las usuarios primero.",
  DoorDetails: "Detalles de la puerta",
  AudioSettings: "Configuración de audio",
  Microphone: "Micrófono",
  Speaker: "Altavoz",
  Volume: "Volumen",
  OperationSent: "Operación enviada",
  NetworkChangedSuccessfullyForDevice: "Se cambió la red con éxito para la puerta",
  FailedChangeNetworkForDevice: "Falló cambiar la red para la puerta",
  error_importing_file_too_large: "Error, el tamaño del archivo es superior a 2 MB",
  Connection: "Conexión",
  AddAUser: "Agregar un Usuario",
  AddToAGroup: "Agregar a un Grupo",
  AddToAll: "Agregar a Todos",
  RemoveFromAll: "Remover de Todos",
  error_site_name_empty: "El nombre del sitio no puede estar vacío",
  error_zone_name_empty: "El nombre de la zona no puede estar vacía",
  HolidaysSettingDescriptionText: "Agregar/Remover días feriados",
  AddHolidays: "Agregar Feriados",
  January: "Enero",
  February: "Febrero",
  March: "Marzo",
  April: "Abril",
  May: "Mayo",
  June: "Junio",
  July: "Julio",
  August: "Agosto",
  September: "Septiembre",
  October: "Octubre",
  November: "Noviembre",
  December: "Deciembre",
  Month: "Mes",
  Day: "Día",
  EditHoliday: "Editar Feriado",
  error_holiday_not_created: "Feriado no creado",
  error_holiday_not_deleted: "Feriado no eliminado",
  error_invalid_date: "Fecha inválida",
  error_holiday_not_found: "Feriado no encontrado",
  error_holiday_not_updated: "Feriado no actualizado",
  Search: "Buscar",
  AdvancedFilter: "Filtro avanzado",
  AssignToAllDoors: "Assignar a todas las puertas del sitio",
  ActivateAutomaticOpenDoor: "Abrir la puerta automáticamente",
  Who: "Quién",
  Where: "Dónde",
  AddToSchedule: "Agregar a un Horario",
  SchedulesWithTheUser: "Horarios con el Usuario",
  ConfirmRemoveTheUserFromTheSchedule: "Confirmar la eliminación del usuario del schedule",
  ScheduleName: "Nombre Horario",
  Repeated: "Repetido",
  GroupName: "Nombre del Grupo",
  RemoveFromGroupSchedule: "Remuevalo del Grupo si desea removerlo del schedule",
  AddManually: "Agregar manualmente",
  DoorWithoutWebAccess: "Puerta sin permiso para abrir por web",
  CaptureTheCardByDevice: "Capturar la tarjeta usando un dispositivo",
  PutTheCardOverTheDevice: "Poner la tarjeta sobre el dispositivo",
  CardAddedForTheUser: "Tarjeta agregada al usuario",
  FailAddCardForTheUser: "No se pudo agregar la tarjeta al usuario",
  FacilityCodeNotAllowed: "Código de la Instalación inválido",
  CardFormatNotAllowed: "Formato de la Tarjeta inválido",
  ThisSiteHasDevicesDelete:
    "El sitio tiene puertas. Cambia las puertas del sitio antes de eliminar la zona",
  error_minimum_number_of_zones: "No puede eliminar todas las zonas",
  ThisSiteHasOnly1Zone:
    "Este sitio tiene solo 1 zona. Tiene que eliminar el Sitio si desea eliminar la zona.",
  ProceedToDeleteTheSite: "Proceder a eliminar el Sitio",
  PublishNonLiveUsers: "Publicar Imagen de Suplantacion de Identidad",
  EnableEmailNotification: "Habilitar notificación por correo",
  TotalAccessLog: "Total de accesos registrados",
  Reports: "Reportes",
  TotalUsers: "Usuarios totales",
  TotalSites: "Total de sitios",
  TotalDoors: "Total de puertas",
  TotalSchedules: "Total de horarios",
  PortalAccessUsers: "Usuarios con acceso al portal",
  AccessLogPerDayChart: "Registro de accesos por día",
  RankingDevicesChart: "Clasificación de dispositivos",
  RankingUsersChart: "Clasificación de usuarios",
  AccessLogCardLegend: "Ultimas 24 horas",
  AccessLogPerType: "Accesos registrados por tipo",
  NoDataChart: "No hay suficientes datos para graficar",
  DevicesConnected: "Puertas conectadas",
  DoorsPerSite: "Puertas por sitio",
  ScheduleStatus: "Estatus de los horarios",
  UserAccessPerDoor: "Accesos de usuarios por puerta",
  ChartTitle: "Gráficas",
  SchedulesWithTheDoor: "Horarios con la Puerta",
  RemoveFromZoneSchedule:
    "Mueva la puerta a otra Zona si desea removerla del schedule. En la pestaña 'Zone' puedes hacerlo",
  ConfirmRemoveTheDoorFromTheSchedule: "Confirmar la eliminación de la puerta del schedule",
  GoToTheZone: "Ir a la zona",
  Alarm: "Alarma",
  AccessLogDescription: "Contiene información completa y análisis sobre los registros de acceso",
  error_device_assign_same_location:
    "Error, está intentando cambiar el dispositivo a su misma ubicación",
  UserActive: "Usuarios activos",
  Granted: "Permitido",
  NotGranted: "No permitido",
  Credential: "Credencial",
  DoorControl: "Control de puertas",
  LiveEventsFeed: "Registro de eventos en vivo",
  TotalEvents: "Total de eventos",
  DeniedNotScheduled: "Denegado (Sin horarios)",
  DeniedUnknownUser: "Denegado (Usuario desconocido)",
  DeniedDeviceInLockdown: "Denegado (Puerta en Modo Bloqueo )",
  DeniedNonLiveUser: "Denegado (Ataque de Suplantación de Identidad)",
  DeniedCardFormatError: "Denegado (Error en Formato Tarjeta)",

  UnlockedAlready: "Ya desbloqueada",
  Denied: "Denegado",
  Picture: "Imagen",
  TheDoorDoesntHaveTheWebCredential: "La puerta no tiene la credential WEB",
  Copied: "Copiado",
  CanNotCopyText: "No se pudo copiar el texto, hagalo manual",
  OpenVideo: "Abrir Video",
  Video: "Video",
  error_invalid_access_result_for_save_images: "No estamos registrando este tipo de acceso",
  DoorVideo: "Video Puerta",
  LostConnectionWithTheDoor: "Conexión perdida con la puerta",
  Failed: "Falló",
  PublishAuthenticatedUsers: "Publicar Imagen Usuarios Autenticados",
  EnrollFaceViaCard: "Inscribir Rostro Via Tarjeta",
  SendPushNotifications: "Enviar Notificaciones Push",
  VideoDrawFocusArea: "Área de Enfoque de Dibujo de Video",
  VideoDrawFaceStats: "Estadísticas de Dibujo de Rostro en Video",
  VideoResolution: "Resolución de Video",
  Pin: "PIN",
  error_invalid_pin: "PIN Inválido",
  EnterPinSentToYou: "Introduzca el PIN enviado a usted",
  Slidetounlockdoor: "Deslice para abrir puerta",
  UserPicture: "Foto del usuario",
  GuestPicture: "Foto del Invitado",
  ChangeToUserPicture: "Cambiar a foto del usuario",
  EventPicture: "Foto del evento",
  ChangeToEventPicture: "Cambiar a foto del evento",
  EnableSmsNotification: "Habilitar notificaciones SMS",
  Low: "Bajo",
  Medium: "Medio",
  High: "Alto",
  Extreme: "Extremo",
  error_cannot_delete_site_with_devices: "No puede eliminar el sitio porque contiene dispositivos",
  UnsupportedCountryCode: "Código de país del teléfono no admitido.",
  SupportAreaDescriptionText:
    "Revisar la comunicación con los representantes de atención al cliente.",
  SupportAccess: "Acceso a Soporte",
  CustomerSupportRepresentative: "Representante de Atención al cliente",
  GiveAccess: "Dar acceso",
  Reference: "Referencia",
  Initiated: "Iniciado",
  SupportName: "Nombre del Soporte",
  SupportEmail: "Email del Soporte",
  SupportPhone: "Teléfono del Soporte",
  ConnectingLiveView: "Conectando al video en vivo",
  LiveView: "Video en Vivo",
  error_group_already_exists: "El grupo ya existe",
  ProvisioningFaceInvitation: "Inivtación para enrolar selfie",
  AddProvisioningFaceInvitation: "Agregar invitación para enrolar autofoto (selfie)",
  ProvisioningFace: "Enrolar selfie",
  ValidatePin: "Validar PIN",
  CannotDeleteInactiveProvisioningFaceInvitation:
    "No se puede eliminar una invitación de aprovisionamiento inactiva",
  CreateProvisioningAlert: "Agregar una nueva invitación invalidará la anterior.",
  error_creating_provisioning_invitation: "Error al crear la invitación",
  error_failed_to_send_sms: "Fallo al enviar SMS",
  error_user_name_required: "Nombre de usuario requerido",
  error_provisioning_expiration_already_passed:
    "El enlace de enrolamiento de selfie ha caducado, comuníquese con su administrador.",
  error_start_datetime_before_end: "La fecha de inicio supera la fecha de termino",
  error_provisioning_invitation_not_found: "Invitación no encontrada",
  error_not_valid_date_range: "Rango de fechas inválido",
  error_not_deactivated: "Invitación no desactivada",
  error_invalid_provisioning_pin: "PIN de aprovisionamiento inválido",
  error_phone_number_used_on_same_account:
    "El número de teléfono ya se utiliza en la misma cuenta.",
  EnableLivenessCheck: "Habilitar verificación de vida",
  ProvisioningKeySuccess: "Tu foto se ha subido correctamente",
  ProvisioningKeyFail:
    "Se ha alcanzado el número máximo de intentos. Solicite otra invitación de aprovisionamiento.",
  CardORKeyFOB: "Tarjeta o FOB",
  CreateAccount: "Crear Cuenta",
  AddAccount: "Agregar Cuenta",
  error_provisioning_max_pin_attempts: "Se excedieron los intentos máximos de PIN",
  error_cloud_key_max_pin_attempts: "Se excedieron los intentos máximos de PIN",

  // Captcha
  captcha_verification_error:
    "Hemos detectado actividad inusual, debes volver a intentarlo o cambiar de navegador.",
  error_captcha_token_required: "El token captcha es requerido",
  Send: "Enviar",
  AddSelfieEnrollmentDescription:
    "El enlace de autorregistro / registro de selfie se enviará al siguiente correo electrónico y número de teléfono móvil registrado para este usuario",
  MobileNumber: "Número mobil",
  AddSelfieEnrollmentTimePeriod:
    "Agregue el período de tiempo válido para el enrolamiento de selfie",
  SelfieEnrollDescrption:
    "Este enlace de autoinscripción le permitirá tomar una foto selfie de su cara, a fin de usar su cara como credencial para acceder de nuevo al instante y de forma segura a las instalaciones/edificio.",
  SelfieEnrollmentProvidePin:
    "Para continuar con el siguiente paso, ingrese el PIN provisto en el correo electrónico o mensaje de texto.",
  Enter: "Ingresar",
  SelfieEnrollStepInstruction:
    "Para un rendimiento óptimo al tomar una selfie de tu cara, sigue estos pasos",
  SelfieEnrollStep1: "Usa una habitación bien iluminada.",
  SelfieEnrollStep2: "Preferiblemente, use una pared sólida detrás de usted.",
  SelfieEnrollStep3: "No tome selfie con la luz del sol detrás de usted.",
  SelfieEnrollStep4: "Asegúrate de que no haya otras caras en la foto del selfie.",
  SelfieEnrollStep5: "No usar anteojos (solo al inscribirse).",
  SelfieEnrollStep6: "Retire el cabello de la cara.",
  SelfieEnrollReadyToTakePhoto: "Cuando estés listo para tomar la foto, haz clic a continuación",
  AnalyzingThePhoto: "Analizando la foto, por favor espera...",
  UnableProcessThePhoto: "No pudimos procesar la foto. Inténtalo de nuevo.",
  SelfieEnrollReady:
    "Ahora está listo para obtener acceso instantáneo y seguro al edificio/instalación utilizando su rostro.",
  ImageReadFailed: "Lectura de imagen fallida",
  ImageConversionFailed: "Error de conversión de imagen",
  SDKWrapperNotInit: "Envoltorio SDK no inicializado",
  DetectFacesFailed: "Deteccion de rostro fallida",
  CalculateFacesQualityFailed: "No se pudo calcular la calidad de los rostros",
  GetMostProminentFacesFailed: "No se pudieron obtener las caras más prominentes",
  DiscardFacesProminenceFailed: "Error al descartar las caras más prominentes",
  DiscardFacesAcceptabilityFailed: "No se pudo descartar la cara debido a la baja aceptabilidad",
  DiscardFacesQualityFailed: "Error. La calidad de la cara es baja",
  DiscardFacesWayTooSmallFailed: "Error. La cara es muy pequeña",
  DiscardFacesTooSmallFailed: "Error. La cara es muy pequeña",
  DiscardFacesTooSmall: "Error. La cara es muy pequeña",
  CalculateFacesLandmarksFailed: "No se han podido calcular los puntos de referencia de las caras",
  CalculateFacesEmbeddingFailed: "No se pudo calcular la incrustación de caras",
  CalculateFrontalityFailed: "Error al calcular la frontalidad",
  GetMostProminentFacesSecondFailed: "No se pudieron obtener las caras más prominentes",
  NotFrontFacing: "No está mirando hacia el frente",
  SelfieEnrollmentTerms: "Al tomar una foto de registro, acepta los siguientes",
  termsandconditions: "términos y condiciones",
  LetsGetStarted: "Comencemos",
  WelcomeToAlocityAccessIntelligence: "¡Bienvenido a Alocity Access Intelligence!",
  WelcomeDescription:
    "Está en camino de modernizar la seguridad de su puerta. Para comprar lectores y suscripciones de Alocity para su cuenta, haga clic a continuación.",
  AlocityAccessIntelligenceSubscription: "Suscripción a Alocity Acceso Inteligente",
  F3D100IntelligentReader: "Lector inteligente F3D100",
  CloudManagementSoftware: "Software de gestión de la nube",
  LiveVideoStreaming: "Transmisión de video en vivo",
  FreeUpdates: "Actualizaciones gratuitas",
  WarrantyLifetimeService: "Garantía de por vida del servicio",
  SelectYourSubscription: "Seleccione su suscripción",
  monthly: "mensual",
  BilledYearly: "Facturado anualmente",
  YearSubscription: "Año de Suscripción",
  HowManyDevicesDoYouWant: "¿Cuántos dispositivos quieres?",
  Continues: "Continuar",
  ContactUsForThisAmount: "Por favor, contáctenos directamente para esta cantidad de dispositivos",

  error_company_address_line1_is_required: "La dirección línea 1 de la empresa es obligatoria",
  error_company_city_is_required: "Se requiere la ubicación de la ciudad de la empresa",
  error_company_state_is_required: "Se requiere el estado de ubicación de la empresa",
  error_company_country_is_required: "Se requiere la ubicación del país de la empresa",
  error_company_postal_code_is_required: "El código postal de la empresa es obligatorio",
  error_company_country_is_invalid: "El país de la empresa no es válido",
  error_company_state_is_invalid: "La ubicación del estado de la empresa no es válida",

  error_shipping_address_line1_is_required: "Se requiere la línea de dirección 1 para el envío",
  error_shipping_city_is_required: "Se requiere la ubicación de la ciudad para el envío",
  error_shipping_state_is_required: "Se requiere la ubicación del estado para el envío",
  error_shipping_country_is_required: "Se requiere la ubicación del país para el envío",
  error_shipping_postal_code_is_required: "Se requiere el código postal para el envío",
  error_shipping_country_is_invalid: "El país de envío no es válido",
  error_shipping_state_is_invalid: "La ubicación del estado de envío no es válida",
  error_cannot_fillShipping_from_account_address:
    "No se pudo actualizar la dirección de envío desde la dirección de la empresa",
  ShippingAddress: "Dirección de Envío",
  Pay: "Pagar",
  Opening: "Abriendo",
  Loading: "Cargando",
  Waiting: "Esperando",
  error_device_not_connected: "El dispositivo está desconectado",
  Unlocking: "Desbloqueando",
  ThanksForYourPayment: "¡Gracias por su pago!",
  ReceiptThisTransaction:
    "Se ha enviado un recibo de esta transacción por correo electrónico para sus registros.",
  UnableToProcessPayment: "No se Puede Procesar el Pago",
  WeAreSorryUnableToProccess:
    "Lo sentimos, no pudimos procesar su pago. Por favor, inténtelo de nuevo más tarde.",
  IfYouNeedHelp: "Si necesita ayuda, póngase en contacto con nosotros en",
  OK: "OK",
  Buy: "Comprar",
  error_creating_checkout_session: "Error al crear la sesión de pago",
  error_creating_new_payment_provider_user:
    "Error al crear un nuevo usuario en nuestro proveedor de pago",
  error_price_is_required: "El ID del precio es obligatorio",
  error_qty_is_required: "La cantidad es requerida",
  error_calculating_shipping: "Hay un error al calcular el envío",
  error_no_other_user_on_site:
    "Un sitio no se puede eliminar de un usuario si es el único en ese sitio",
  CompanyAddress: "Dirección de la compañia",
  SameCompanyAddress: "Utilizar la dirección de la compañia como dirección de envío",
  ErrorSameAsCompanyAddress: "Primero agregue la dirección de la compañia",
  FaceOnCard: "Rostro en Tarjeta",
  error_conflict_identification_workflow:
    "Rostro en tarjeta no puede ser utilizado en los flujos q contengan tarjeta.",
  WorkflowStartsWith:
    "La secuencia de entrada comienza con una opcion ya existente. Si desea comenzar una secuencia que comience con esta opcion, por favor elimine la secuencia existente.",
  error_invalid_credential_type: "Error. Tipo de credencial inválido",
  ClickToUnlock: "Clic para desbloquear puerta",
  error_campaign_not_all_devices_updated:
    "No todos los dispositivos están actualizados al último firmware",
  Enterpinsentintheemail: "Introduzca el PIN enviado por email",
  DeviceUpdate: "Actualizar Dispositivo",
  TextUpdateEnhancementsCampaign:
    "Al presionar el botón 'Actualizar' se enviará una actualización a todos los dispositivos conectados a su cuenta. Por favor asegúrese de que todos sus dispositivos estén conectados para que puedan recibir la actualización.",
  DeviceUpdateCampaign: "Campaña de Actualización de los Dispositivos",
  Clicktounlockdoor: "Pulsa para abrir puerta",
  ScheduleUpdate: "Actualice los Dispositivos antes de",
  error_account_campaign_not_found: "Campaña no Encontrada",
  Integration: "Integración",
  IntegrationDescription: "Las integraciones le permiten crear aplicaciones de terceros.",
  APIKeys: "Claves de API",
  StandardKeys: "Claves Estandard",
  StandardKeysDescription: "Estas claves le permitirán autenticar solicitudes de API.",
  LearnMore: "Leer más",
  Token: "Token",
  RevealKey: "Revelar clave",
  HideKey: "Ocultar clave",
  LastUsed: "Utilizado por última vez",
  RollKey: "Cambiar clave",
  ViewRequestLogs: "Ver registro de solicitudes",
  ErrorMicrophonePermissionDenied:
    "Debe habilitar los permisos del micrófono si desea enviar audio.",

  // ApiKeys:
  error_access_log_not_found: "El registro de acceso no existe",
  ConfirmPasswordToAccessKeys: "Para acceder a la clave, por favor confirme su contraseña.",
  Key: "Clave",
  AdditionalAuthenticationRequired: "Se necesita una authenticación adicional",
  ConfirmPasswordToContinue: "Para continuar, por favor ingrese su contraseña.",
  AddFirstAPIKey: "Crear la primer clave de API",
  UseOfApiKey: "Uso de una clave de acceso",
  UseOfApiKeyDescription:
    "Para obtener un token para autorizar sus llamadas a la API, utilice esta url.",
  UseOfApiKeyDescription2: "Puede obtener más información aquí: ",

  error_identity_user_login: "Error: La contraseña es incorrecta",
  Copy: "Copiar",

  // Webhooks
  Webhooks: "Webhooks",
  AddWebhooks: "Agregar Webhooks",
  AddWebhook: "Agregar Webhook",
  WebhookInfo: "Información del Webhook",
  URL: "URL",
  SelectEvents: "Seleccionar eventos",
  SelectedEvents: "Eventos seleccionados",
  SelectEventsToSend: "Seleccionar eventos para enviar",
  SelectAllEventsOf: "Seleccionar todos los eventos de",
  SearchEvents: "Buscar eventos",
  AddEvents: "Agregar eventos",
  SelectAllEvents: "Seleccionar todos los eventos",
  Listening: "Escuchando",
  Secret: "Llave Secreta",
  RevealSecret: "Revelar",
  EventsUpdated: "Eventos actualizados",
  error_account_webhook_url_in_use: "La URL ya está en uso",
  error_webhook_not_found: "Webhook no encontrado",

  ErrorWithTheServiceToValidateAddress:
    "Error con el servicio para validar la dirección. Por favor, inténtalo de nuevo",
  AddressNotFound: "Dirección no encontrada. Por favor, verifíquela",
  Unlock: "Abrir",
  error_reading_max_on_wall_capacity:
    "Error al leer la capacidad de configuración de la pared del intercomunicador",
  error_wall_intercom_capacity_is_full: "Capacidad de intercomunicación llena",
  error_device_already_on_wall: "Dispositivo ya en el muro",
  error_device_already_off_wall: "Dispositivo ya fuera del muro",
  AlreadyOnTheWall: "Ya esta en el muro",
  ErrorDetails: "Detalles del Error",
  AllowMicrophone: "Permitir usar su micrófono",
  AlocityNeedAccessToYourMic:
    "Alocity necesita acceso a su micrófono para que la persona frente al dispositivo pueda escucharlo.",
  MicrophoneBlocked: "El micrófono está bloqueado",
  ClickTheCameraIcon: "Haga clic en el icono de cámara bloqueada",
  inYourBrowserAddressBar: "en la barra de direcciones de tu navegador",
  Retry: "Intentar de Nuevo",
  CanNotUseMicro: "No puedo usar su micrófono",
  YourBrowserMightNotHaveAccess:
    "Es posible que su navegador no tenga acceso a su micrófono. Para solucionar este problema, abra",
  AnotherApplicationUsingYourMic:
    "Es posible que otra aplicación (Zoom, Webex) o una pestaña del navegador (Google Meet, Messenger Video) ya esté usando su micrófono. Apague el otro micrófono antes de continuar.",
  DoorRinging: "Timbre de la Puerta",
  Check: "Revisar",
  VideoIntercom: "Video de Intercomunicación",
  VideoIntercomSidebar: "Video Intercom",
  DeviceSelection: "Selección de dispositivo",
  Live: "Vivo",
  error_user_with_same_phone_number: "Este número de teléfono ya está en uso",
  YouDontHavePermissionToSetThisRole: "No tienes permiso para establecer este rol",
  ReissueAccessKey: "Reemitir clave de acceso",
  Visitors: "Invitados",
  Visitor: "Invitado",
  AddVisitor: "Agregar Invitado",
  TextAddVisitorStep1: "Info del Invitado",
  TextAddVisitorStep2: "Puertas Acceso",
  ArrivalDate: "Fecha de Llegada",
  DepartureDate: "Fecha de Salida",
  PleaseSelectTheVisitors:
    "Seleccione los horarios para vincular al nuevo huésped. Al hacerlo, proporcionará acceso a las puertas en los momentos especificados.",
  Reuse: "Reutilizar",
  VisitorsSchedules: "Horarios de Invitados",
  VisitorScheduleIsEmpty:
    "No se ha creado ningún horario de invitados. Si desea crear el usuario sin horario, haga clic aqui",
  CreateAVisitorWithoutSchedule: "Crear un invitado sin horario",
  CheckVisitorsSchedules: "Revisar Horarios de Invitados",
  VisitorInfo: "Información del Invitado",
  SchedulesWithTheVisitor: "Horarios al que Pertenece",
  RequiredTheEmailOrPhoneIsEmpty:
    "Requerido un correo electrónico o un número de teléfono. Por favor, agregue estos valores.",
  VisitorsInTheSchedule: "Invitados en el Horario",
  ThereAreNotVisitorsToRemove: "No hay invitados para eliminar",
  ThereAreNotVisitorsToAdd: "No hay invitados para agregar",
  ConfirmRemoveVisitorFromTheSchedule: "Confirmar Eliminar invitado del Horario",
  ConfirmAddAllVisitorsToTheSchedule: "Confirmar Agregar a Todos los Invitados al Horario",
  ConfirmRemoveAllVisitorsFromTheSchedule: "Confirmar Eliminar Todos los Invitados del Horario",
  VisitorsThereNotInTheSchedule: "Los Invitados no están en el horario",
  AddVisitorSchedule: "Agregar Horario de Invitado",
  VisitorScheduleSettingDescriptionText: "Agregar/Remover los horarios de los invitados",
  ConfirmRemoveTheVisitorFromTheSchedule: "Confirmar la eliminación del invitado del schedule",
  VisitorSchedulesWithTheDoor: "Horarios de Invitados con la Puerta",
  error_guest_not_created: "No se pudo crear el invitado.",
  error_guest_not_deleted: "No se pudo eliminar el invitado.",
  error_guest_email_already_used_on_same_account: "El correo electrónico ya se usa en la cuenta",
  error_invalid_dates: "Fechas inválidas",
  error_guest_not_found: "Invitado no encontrado",
  error_guest_not_updated: "Invitado no actualizado",
  AssignedAlready: "Ya asignado",
  AddToASchedule: "Agregar a un horario",
  AddUserToASchedule: "Agregar el usuario al horario",
  AddVisitorToASchedule: "Agregar el invitado al horario",
  Workflows: "Flujos de trabajo",
  AddWorkflow: "Agregar Flujo de Trabajo",

  wf_personofinterest_observed: "Persona de Interés Observada",
  wf_accesslog_unauthorized: "Intento de Acceso no Autorizado",
  wf_door_keepopen: "La Puerta Permanece Abierta",
  wf_door_offline: "El Estado de Conexión de Puerta Cambió",
  wf_door_openremote: "Puerta Abierta Remotamente",
  wf_door_settingschanged: "La Configuración de la Puerta Cambió",
  wf_door_forceopen: "Forzaron la Puerta Abierta",
  wf_accesslog_authorized: "Acceso Autorizado",
  wf_lockdown_start: "Empezar Plan de Cierre de Emergencia",
  wf_lockdown_end: "Detener Plan de Cierre de Emergencia",
  wf_sensor_triggered: "Sensor Activado",
  wf_ioerror_detected: "Error de IoT Detectado",

  ActiveFrom: "Activo desde",
  ActiveTo: "Activo hasta",
  ActorTypes: "Tipos de Acciones",
  ConfirmActivation: "Confirmar Activación",
  AccessLogTypes: "Tipos de registro de acceso",
  TitleTextAddWorkflowOption1: "Seleccione el disparador para el flujo de trabajo.",
  Triggers: "Disparadores",
  GeneralLockDown: "Bloqueo General",
  GeneralSoundAlarm: "Sonar Alarma General",
  LockDownDoor: "Bloquear Puerta",
  SendEmail: "Enviar Email",
  SendSMS: "Enviar Mensaje de Texto",
  SoundDoorAlarm: "Sonar Alarma de Puerta",
  TitleTextAddWorkflowOption2:
    "Ahora tienes que configurar cuales son las propiedades del disparador",
  SelectDoorThatYouWantListening: "Seleccione las puertas que desea escuchar",
  SelectTypeAccess: "Seleccione el tipo de acceso",
  TitleTextAddWorkflowOption3: "Elija el intervalo de tiempo para este flujo de trabajo.",
  Time24hours: "24 horas",
  TitleTextAddWorkflowOption4: "Seleccione una o más acciones para este flujo de trabajo.",
  TitleTextAddWorkflowOption5: "Escriba una descripción que ayude a comprender su flujo de trabajo",
  WorkflowInfo: "Detalle de Flujo de Trabajo",
  ActiveTime: "Tiempo Activo",
  error_account_workflow_exists: "Hay un flujo de trabajo en la cuenta con el mismo disparador",
  error_workflow_code_notfound: "No se encontró el código de activación del flujo de trabajo.",
  error_workflow_notfound: "Flujo de trabajo no encontrado",
  error_workflow_update_failed: "Error al actualizar el flujo de trabajo",
  Editing: "Editando",
  Avatar: "Avatar",
  error_deleting_cloud_key: "La eliminación de claves de nube para esta usuario ha fallado.",
  error_not_active_yet: "El invitado no está activo actualmente.",
  Definition: "Definición",
  OR: "O",
  ItIsForLockdown: "Es una tarjeta de cierre de emergencia",
  LockdownPlans: "Planes de cierre de emergencia",
  LockdownPlans2: "Planes de Cierre",
  Revert: "Revertir",
  Disabled: "Desactivado",
  Reverted: "Revertido",
  error_lockdown_plan_not_enabled:
    "El plan de cierre de emergencia no está habilitado en esta cuenta.",
  error_workflow_invalid_trigger_actor: "Invalid action(s) found for this trigger.",
  error_external_card_can_not_lockdown: "An external card cannot be used as a lockdown card.",
  error_allow_unencrypted_cannot_lockdown:
    "You cannot register a lockdown card if you are allowing unencrypted cards.",
  error_guest_user_cannot_enroll_lockdown_card:
    "Los invitados no pueden tener tarjetas de bloqueo.",
  error_workflow_not_authorized_user:
    "No estas autorizado para empezar o detener el cierre de emergencia.",
  error_expiration_already_passed: "La fecha de caducidad ya pasó.",
  ErrorTheLockdownActorNotFound:
    "No se encontró el código de acción de flujo de trabajo 'Puerta de bloqueo' o 'Bloqueo general'.",
  LockdownStarted: "Iniciado Cierre de Emergencia",
  LockdownEnded: "Cierre de Emergencia Terminado",
  TitleTextAddWorkflowOptionTargetUserGroup:
    "Seleccione los usuarios de destino de este flujo de trabajo",
  TitleTextAddWorkflowOptionTargetDoors:
    "Seleccione las puertas de destino de este flujo de trabajo",
  TargetDoors: "Puertas de Destino",
  TargetZones: "Zonas de Destino",
  TargetSites: "Sitios de Destino",
  TargetUsers: "Usuarios Objetivo",
  TargetGroups: "Grupos Objetivo",
  ActionTargets: "Objetivos de las Acciones",
  TitleTextAddWorkflowOptionLockDownPlan:
    "Seleccione los usuarios autorizados para activar un cierre de emergencia.",
  TitleTextAddWorkflowOptionExpiresOnLockdownPlan: "(Opcional) Seleccione el tiempo de caducidad.",
  UsersWillTrigger: "Los usuarios que activarán",
  GroupsWillTrigger: "Los grupos que activarán",
  error_card_already_exists:
    "Ya existe una tarjeta con este código de instalación y número de tarjeta.",
  WorkflowAreaDescriptionText:
    "Configure flujos de trabajo para automatizar tareas y enviar notificaciones a usuarios después de que ocurra un evento.",
  AllowUnencryptedCards: "Permitir Tarjetas sin Cifrar",
  TextExplainReasonNotLockdownCardWithDefault:
    "No se pueden crear tarjetas de bloqueo predeterminadas cuando se permiten tarjetas sin cifrar en la configuración de la cuenta.",
  EnableLockdownPlan: "Habilitar el Plan de Cierre de Emergencia",
  LockdownPlanDuration: "Duración del Plan de Cierre de Emergencia (minutos)",
  HelperLockdownPlanDuration: "El plan estará activo hasta que alguien lo detenga",
  CardEndianType: "Tipo de Tarjeta Endian",
  Little: "Poco",
  Big: "Grande",
  CardOffsetNumber: "Longitud en Bits Número de Tarjeta",
  CardOffsetFacility: "Longitud en Bits Código de Instalación de Tarjeta",
  CardOffsetNumberStart: "Bit Inicial del Número de Tarjeta",
  CardOffsetFacilityStart: "Bit Inicial de Código de Instalación de Tarjeta",
  TriggerTargets: "Objetivos del Disparador",
  AddLockdownPlanCard: "Agregar una tarjeta de bloqueo",
  error_cannot_create_lockdown_card_for_user:
    "Este usuario no está autorizado a tener una tarjeta de bloqueo",
  error_lockdown_workflow_required_make_card:
    "Se requiere un flujo de trabajo de bloqueo para hacer una tarjeta de bloqueo",
  error_device_no_cloud_key_access: "Este dispositivo no permite el acceso a la clave de la nube",
  error_workflow_missing_trigger_targets: "Faltan los objetivos del disparador",
  GeneralLockdownActive: "El bloqueo de emergencia está activo",
  Duration: "Duración",
  error_workflow_delete_failed: "No se pudo eliminar el flujo de trabajo",
  error_workflow_missing_trigger_targets_1:
    "No se encontraron usuario/grupo para ejecutar el disparador",
  error_workflow_missing_trigger_targets_2: "No se encontraron puertas para ejecutar el disparador",
  error_workflow_missing_trigger_targets_3:
    "No se encontraron sensores para ejecutar el disparador",

  ErrorMissingTypeAccess: "Falta el tipo de acceso",
  ErrorMissingActions: "Faltan las acciones",
  error_workflow_missing_action_targets_1:
    "No se encontraron usuario/grupo para ejecutar las acciones",
  error_workflow_missing_action_targets_2: "No se encontraron puertas para ejecutar las acciones",
  error_workflow_missing_action_targets_3: "No se encontraron sensores para ejecutar las acciones",
  MicrophoneInUseBy: "Usando el micrófono por",
  ErrorGenericWebRTC: "No podemos conectarnos al dispositivo o ya hay 10 conexiones",
  Reconnect: "Reconectar",
  ExactEmail: "Email (debe ser exacto)",
  error_group_has_assigned_users: "El grupo todavía tiene algunos usuarios.",
  error_user_already_on_group: "Uno de los usuarios ya está en ese grupo.",
  PublishAccesslogVideoEvents: "Publicar eventos de video de registro de acceso",
  WatchVideoEvent: "Ver Video Evento",
  ThereIsnoEventVideo: "No hay video del evento",
  TheEventVideoExpire: "El video del evento caducó",
  EventVideo: "Video del Evento",
  Started: "Empezó",
  error_video_notfound: "Video no encontrado en la nube",
  Share: "Compartir",
  ExpirationInMinutes: "Vencimiento en minutos",
  Contact: "Contacto",
  GreaterThan5: "Mayor que 5",
  LessThan720: "Menos de 43200",
  Error_MEDIA_ERR_NETWORK: "Se produjo un error de red al obtener el video.",
  Error_MEDIA_ERR_SRC_NOT_SUPPORTED: "Falta el video o la autorización expiró.",
  YouChangedYourEmailTo: "Vuelva a iniciar sesión porque cambió su correo electrónico a",
  EventHistory: "Historial de Eventos",
  EventHistoryDescription: "Puede buscar todos los eventos que suceden en sus puertas",
  TheDeviceIsUploadingTheVideo: "El dispositivo está subiendo el video.",
  TheSettingToSaveTheVideoIsOff: "La configuración para guardar el video está desactivada.",
  error_provisioning_max_attempts:
    "Se ha alcanzado el número máximo de intentos. Solicite otra invitación de aprovisionamiento.",
  error_one_face_required: "El sistema requiere 1 rostro",
  error_mask_detected: "Error, se detectó una máscara",
  TheLinkIsInvalid: "El enlace no es válido",
  NFCTag: "Etiquetas NFC",
  EnableNFCTagRead: "Habilitar Lectura Etiqueta NFC",
  SelectTheVisitorHostDescription: "Seleccione el usuario que va a ser el anfitrión del visitante",
  UserCreatedDate: "Fecha de Creación de Usuario",
  GuestCreatedDate: "Fecha de Creación del Invitado",
  Host: "Anfitrión",
  MobileCredential: "Credencial Móvil",
  error_user_no_mobile: "El usuario no tiene credenciales de móvil",
  PublishAccesslogAuthenticatedVideoEvents: "Publicar Video Usuario Autenticado",
  PublishAccesslogUnknowUserVideoEvents: "Publicar Video Usuario Desconocido",
  PublishAccesslogSpoofVideoEvents: "Publicar Video de Suplantación de Identidad",
  AddFace: "Agregar Rostro",
  FaceEnrollStepInstruction:
    "Para un rendimiento óptimo al tomar una foto del usuario, sigue estos pasos",
  FaceEnrollStep1: "Usa una habitación bien iluminada.",
  FaceEnrollStep2: "Preferiblemente, use una pared sólida detrás del usuario.",
  FaceEnrollStep3: "No tome selfie con la luz del sol detrás del usuario.",
  FaceEnrollStep4: "Asegúrate de que no haya otras caras en la foto.",
  FaceEnrollStep5: "El usuario no debe usar anteojos (solo al inscribirse).",
  FaceEnrollStep6: "Retire el cabello de la cara.",
  FaceEnrollReadyToTakePhoto: "Cuando estés listo para tomar la foto, haz clic a continuación",
  AddMobileCredential: "Agregar Credencial Móvil",
  RefreshMobileCredential: "Actualizar la Credencial Móvil",
  Refresh: "Actualizar",
  WirelessConnection: "Conexión Inalámbrica",
  EnableHandsFree: "Habilitar Manos Libre",
  DayOfWeek: "Días de la Semana",
  Days: "Días",
  Catalog: "Directorio",
  CatalogDescription: "Crear un directorio donde las personas puedan contactar a los usuarios.",
  AddEntry: "Agregar Entrada",
  DisplayName: "Descripción",
  AddDirectoryEntry: "Agregar Entrada al Directorio",
  Doorbell: "Timbre",
  DeviceDisconnectedCanNotNotify:
    "Este dispositivo está actualmente fuera de línea y no puede enviar la notificación. Por favor, inténtelo de nuevo más tarde.",
  error_catalog_not_found: "Directorio no encontrado",
  error_account_catalog_update_error: "Directorio no actualizado",
  error_account_add_catalog: "Directorio no agregado",
  error_account_catalog_delete_error: "No se pudo eliminar",
  error_device_under_lockdown: "Error, la puerta está bloqueada",
  Contain8Characters: "- Contenga 8 caracteres",
  Contain1Number: "- Contenga 1 número",
  Contain1Uppercase: "- Contenga 1 mayúscula",
  error_device_hands_free_disabled: "Error, dispositivo manos libres deshabilitado",
  DNSServer: "Servidor DNS",
  TheLastNumberCannotByZero: "El último número no puede ser cero",
  TheFirstNumberGreaterThan128: "El primer número debe >= 128",
  WebRTCEnabled: "Habilitar Video en Vivo",
  WebRTCAudioEnabled: "Habilitar Audio en Video en Vivo",
  RTSPSettings: "Configuración RTSP",
  error_device_webrtc_disabled: "La conexión en vivo está deshabilitado para este dispositivo",
  LiveVideoAudioDisabled: "El audio del video en vivo está deshabilitado para este dispositivo",
  error_last_site: "No se puede eliminar el último sitio de la cuenta",
  error_account_catalog_user_has_already: "El usuario ya tiene una entrada de catálogo",
  ExpirationDateCannotBeInThePast: "La fecha de caducidad no puede estar en el pasado",
  error_provisioning_face_matched_different_user: "Su cara se encontró en otro usuario",
  error_card_lost_or_stolen:
    "Esta combinación de número de tarjeta y código de instalación ha sido marcado como robado, por lo que no se puede usar",
  ReportLostOrStolen: "Reportar pérdida or robada",
  ThisOperationIsCritical: "Esta operación es crítica",
  ThisOperationPermantlyDelete:
    "Esta operación eliminara permanentemente esa tarjeta por lo que la combinación de número de tarjeta y código de instalación no se podrá usar de nuevo",
  DeletedPermanently: "Eliminada permanentemente",
  AddSensor: "Agregar Sensor",
  DeviceSerialNumber: "Puerta Numero de Serie",
  SensorType: "Tipo de Sensor",
  Relay: "Relé",
  Switch: "Interruptor",
  SensorInfo: "Información de Sensor",
  error_sensor_not_found: "Falló, sensor no encontrado",
  error_creating_sensor: "Error creando el sensor",
  error_updating_sensor: "Error actualizando el sensor",
  error_deleting_sensor: "Error eliminando al sensor",
  error_max_reached:
    "Se a alcanzado el número máximo de sensores este dispositivo, no se puede agregar más de 10 sensores",
  RelayAndSwitch: "Relé e interruptor",
  GetURL: "Mostrar la URL",
  Zigbee: "Zigbee",
  SelectZigbeeContact: "Seleccionar el Contacto Zigbee",
  HelpZigbeeAppearList:
    "Tienes que registrar un contacto Zigbee a esta Puerta para aparecer en esta lista",
  error_null_access_control_settings:
    "El sensor de contacto no puede estar vacío cuando la posición del sensor es Zigbee",
  error_null_exit_button:
    "El botón de salida no puede estar vacío cuando la posición del sensor es Zigbee",
  error_null_contact_sensor: "La configuración de la puerta no puede estar vacía",
  ZigbeeRelay: "Relé Zigbee",
  SelectZigbeeSwitch: "Seleccionar el interruptor Zigbee",
  error_sensor_log_artifact_not_image: "Este artefacto no es una imagen.",
  error_sensor_log_artifact_not_video: "Este artefacto no es un video.",
  error_sensor_log_artifact_not_found: "No se econtro el artefacto",
  CaptureImage: "Capturar Imagen",
  CaptureVideo: "Capturar Video",
  IssueSensorCommand: "Emitir Comando para Sensor",
  SelectTheSensorsWillTrigger: "Seleccione los sensores que van a activar el flujo de trabajo",
  TitleTextAddWorkflowOptionTargetSensors:
    "Seleccione los sensores de destino de este flujo de trabajo",
  TriggerSensors: "Sensores Disparadores",
  TargetSensors: "Sensores Objetivos",
  Paired: "Vinculado",
  SelectTypeOfZigbee: "Seleccione el tipo de sensor Zigbee que le gustaría agregar",
  DoorWindowContactSensor: "Sensor de Contacto de Puerta/Ventana",
  TrackEveryTimeDoorOpened:
    "Realiza un seguimiento cada vez que se abre o se deja entreabierta una puerta/ventana.",
  AssociateVideoFromSelectedCamerasDoorOpened:
    "Asociar video de las cámaras seleccionadas cuando la puerta/ventana se abre.",
  CreateCustomizedWorkflowsToTriggerEvents:
    "Cree flujos de trabajo personalizados para activar eventos, alarmas y notificaciones.",
  MotionSensor: "Sensor Movimiento",
  TractEveryTimeMotion: "Realiza un seguimiento cada vez que se detecta movimiento.",
  AssociateVideoFromSelectedCameras:
    "Asocie el video de las cámaras seleccionadas cuando se detecte movimiento.",
  ConfigureAsAutomaticRex: "Configurar como 'solicitud de salida' automático.",
  CreateCustomizedWorkflowsToTrigger:
    "Crear flujos de trabajo personalizados para activar eventos, alarmas y notificaciones.",
  WirelessRelay: "Relé Inalámbrico",
  SendOpenCommandWhen:
    "Envía un comando de apertura cuando un usuario está autenticado con el lector Alocity F3D100.",
  EliminateTheNeedsToRunWires: "Elimina la necesidad de instalar cables.",
  ComunicationIsEncryptedAndSecure: "La comunicación es encriptada y segura.",
  PushPanicExitButton: "Pulsador / Pánico / Botón de Salida",
  CustomizeTheButtonTrigger: "Personaliza el botón para activar varios métodos:",
  REXRequestToExit: "- Solicitud de Salida",
  PanicICaseOfEmergency: "- Pánico, en caso de emergencia avisar a administradores y autoridades.",
  LockdownCreatePlans: "- Bloqueo, cree planes para bloquear puertas al instante.",
  OpenUseAsRemoteToOpenDoor: "- Abrir, usar como control remoto para abrir la puerta",
  FollowTheStepsBelowToAddTheZigbeeSensor:
    "Siga los pasos a continuación para agregar el sensor Zigbee:",
  AddSensorStep1:
    "1. Seleccione el lector de puerta Alocity que se utilizará para emparejar con el sensor Zigbee.",
  AddSensorNote1:
    "Nota: El sensor y el lector de Alocity deben estar dentro de un rango de 30 a 5 pies.",
  AddSensorStep2: "2. Coloque el sensor Zigbee en modo de emparejamiento.",
  AddSensorNote2:
    "Nota: siga las instrucciones de emparejamiento del fabricante del sensor Zigbee.",
  AddSensorStep3:
    "3. Haga clic en el botón Conectar Ahora para iniciar el proceso de emparejamiento.",
  AddSensorNote3:
    "Nota: Una vez que el dispositivo Alocity comience a buscar sensores, comenzará a parpadear en verde.",
  ConnectNow: "Conectar Ahora",
  SearchingDots: "Buscando ...",
  Pairing: "Emparejando",
  PleaseAddNameDescriptionSensor:
    "Por favor, añade un nombre y una descripción para ayudarte a identificar tu sensor:",
  PairedDeviceSerialNumber: "Número de serie del dispositivo emparejado",
  Image: "Imagen",
  SensorLogs: "Registros de los Sensores",
  error_sensor_in_use: "El sensor se está utilizando como contacto, botón de salida o relé",
  error_sensor_log_not_found: "Registro del sensor no encontrado",
  LightSwitch: "Interruptor de Luz",
  TurnLightsOnOffRemotely: "Encender/apagar luces desde la aplicación y de forma remota.",
  SetSchedulesAutomaticallyLightsOnOff:
    "Establecer horarios para encender/apagar las luces automáticamente",
  CreateCustomizedWorkflowsTurnLight:
    "Cree flujos de trabajo personalizados (p. ej., encienda las luces si se activa una alarma o un movimiento fuera del horario de atención)",
  SirenAlarm: "Sirena / Alarma",
  SetWorkflowsToTriggerTheSirenWhenACriticalEventHappens:
    "Establezca flujos de trabajo para activar la sirena cuando ocurra un evento crítico (por ejemplo, la puerta se abre a la fuerza, se detecta movimiento fuera del horario de atención)",
  Artifacts: "Artefactos",
  Images: "Imágenes",
  Videos: "Videos",
  ReadMore: "Leer más",
  MainServicesAgreement: "Acuerdo de Servicios Principales",
  BiometricPrivacyConsent: "Privacidad biométrica",
  Legal: "Legal",
  Battery: "Batería",
  SignalIntensity: "Intensidad de la Señal",
  error_max_internal_exit_button: "No puede tener más de 1 botón de salida interno",
  error_max_external_exit_button: "No puede tener más de 1 botón de salida externo",
  ExitButtons: "Botones de Salida",
  error_max_exit_button: "No puede tener más de 6 botones de salida",
  HelpZigbeePushButtonAppearList:
    "Tienes que registrar un sensor de movimiento o botón pulsador Zigbee a esta Puerta para aparecer en esta lista",
  HelpZigbeeRelayAppearList:
    "Tienes que registrar un relé Zigbee a esta Puerta para aparecer en esta lista",
  error_zigbee_exit_button_used_more_than_once: "No puede usar el mismo sensor",
  NetworkError: "Error en la Red",
  Playback: "Reproducción",
  PlaybackSettings: "Configuración de Reproducción",
  Find: "Buscar",
  error_devicetype_has_noevents: "No hay eventos",
  error_device_log_artifact_not_image: "No hay imagen en el registro",
  error_device_log_artifact_not_video: "No hay video en el registro",
  error_device_log_artifact_not_found: "No hay artefacto",

  FaceDetection: "Detección de Rostro",
  MotionDetection: "Detección de Movimiento",
  ObjectDetection: "Detección de Objetos",
  PersonDetection: "Detección de Personas",
  PetDetection: "Detección de Animal",
  SoundDetection: "Detección de Sonido",
  VehicleDetection: "Detección de Vehículos",
  EventNoavailable:
    "Este dispositivo no está grabando este evento porque en la configuración de cuenta está desactivado.",
  to: "a",
  VideoNotFoundInDevice: "Video no encontrado en el dispositivo",
  SelectDateToReview: "Seleccione la fecha y la hora de la reproducción de video que desea revisar",
  DeviceIsNotRecording:
    "Este dispositivo no está grabando eventos porque la configuración está desactivada.",
  Person: "Persona",
  Pet: "Animal",
  Object: "Objeto",
  Sound: "Sonido",
  ChangeEventSettings: "Cambiar la Opciones de Eventos",
  EventThatYouWant: "Puedes activar o desactivar los eventos que quieras grabar",
  ChangeHardwareZoom: "Cambiar Zoom de Hardware",
  HardwareZoom: "Hardware Zoom",
  EventSettings: "Configuración de eventos",
  ZoomIn: "Acercarse",
  ZoomOut: "Alejarse",
  Screenshot: "Captura de Pantalla",
  Fullscreen: "Pantalla Completa",
  MissingPermission: "Permisos faltantes",
  Connecting: "Connectando",

  NumberOfArtifacts: "Total de Dispositivos",
  NumberOfEvents: "Total de Eventos",
  ActivityPerCategory: "Actividad Total",
  EventLogs: " Registro de eventos",
  TableofEventLogsPerDevice: "Tabla de registro de eventos por dispositivos",
  TableofArtifactsPerDevice: "Tabla de registro de artefactos por dispositivos",
  CamerasDashboard: "Panel de Cámaras",
  EventImage: "Imagen del Evento",
  ConnectingToDevice: "Connectando al Dispositivo",
  AlocityDevice: "Equipo Alocity",
  EnterTheSerialNumber: "Introduzca el número de serie",
  Upload: "Subir",
  error_device_partnerid: "Dispositivo no asociado a un socio",
  error_device_not_eligible: "Dispositivo no elegible para la operación",
  SelectDateRange: "Selecciona el rango de tiempo a consultar",
  TimeDifferenceExceeds1Hour: "La diferencia horaria supera 1 hora",
  Top: "Arriba",
  LastImage: "Última Imagen",
  Preview: "Vista anticipada",
  AutoFocus: "Enfoque Automático",
  RowsPerPage: "Filas por página",
  BatchSelfie: "Autofoto por Lotes",
  BatchSelfieDescription: "Enviar invitación de foto de registro a todos los usuarios.",
  TextConfirmationSelfieBatch:
    "Al presionar el botón 'Enviar' se enviará una invitación de inscripción a todos los usuarios actualmente activos asociados con su cuenta y que no tengan una cara inscrita.",
  Text2ConfirmationSelfieBatch:
    "Asegúrese de que todos sus usuarios posean direcciones de correo electrónico o números de teléfono que funcionen para facilitar la recepción del enlace de invitación.",
  Text3ConfirmationSelfieBatch:
    "Es importante tener en cuenta por razones de seguridad que este enlace permanecerá válido por una duración de 24 horas.",
  CameraInfo: "Información de la Cámara",
  Activity: "Actividad",
  RTSP: "RTSP",
  Chunks: "Trozos",
  Hours: "Hora (s)",
  Minutes: "Minutos",
  MEDIA_ERR_SRC_NOT_SUPPORTED: "ERROR SRC NO SOPORTADO",
  MEDIA_ERR_NETWORK: "ERROR DE RED ",
  ERROR_VIDEO: "ERROR",
  ErrorHLSSupport: "Algunos códecs no son compatibles con este navegador",
  SelectDevice: "Elija una de las cámaras asociadas a la zona del evento para su reproducción ",
  CameraVideoEvent: "Evento de vídeo de las cámaras ",
  DevicesNoFound: "Dispositivos no encontrados en esta zona",
  NotFound: "No encontrado",
  ActiveCamera: "Activas",
  DesactiveCamera: "Desconectadas",
  ActivityByCameras: "Actividad por Cámaras",
  RecentActivity: "Actividades recientes",
  NoConnectCamera:
    "En este momento no hay cámaras conectadas, por favor cambie de opción en el filtro",
  NoDisconnectCamera:
    "En este momento no hay cámaras desconectadas, por favor cambie de opción en el filtro",
  ThereAreNotCameras: "No hay cámaras disponibles",
  TableAccessLogs: "Tabla de registro de acceso del usuario",
  ModeVideoPlayBack: "Modos de reproducción:",
  Last10Log: "(Últimos 10 accesos)",
  NA: "N/A",
  SDLow: "SD Low",
  CloudHigh: "Cloud High",
  CloudLow: "Cloud Low",
  EventArtifacts: "Artefactos del evento",
  Downloading: "Descargando",
  ShowEvents: "Mostrar Eventos",
  ThereIsADownloadInProccess: "Hay una descarga en proceso",
  NotHadwareZoom: "La cámara no posee una funcionalidad de zoom",
  ModeSDLow: "El mode de reproducción es SD",
  PreparingVideo: "Preparando vídeo",
  Clips: "Clips:",
  Hour: "Hora",
  FailedConnectionWithDevice: "Falló la conexión con el dispositivo",
  LimitCharacters: "El límite máximo de caracteres es 36",
  NoEmptyFields: "No puede haber campos vacíos",
  Autogenerate: "Autogenerar",
  Export: "Exportar",
  SelectTheDateRange: "Seleccione el rango de fecha",
  GreaterThan30Days: "Mayor que 30 días",
  DateRange: "Rango de Fechas",
  ExportToCSV: "Exportar a CSV",
  IncludeAvatar: "Incluir User Picture",
  NoDataFound: "No se encontraron datos",
  HideURL: "Ocultar la URL",
  SettingsAreOff: "La grabación de vídeo está desactivada.",
  UploadingVideoNow: "Subiendo video, por favor espere ...",
  Operations: "Operaciones",
  SelecWhatOperationYouWantOverTheRangeOfTimes:
    "Seleccione qué operación desea realizar en el rango de tiempos",
  Start: "Empezar",
  Reactivate: "Reactivar",
  Suspend: "Suspender",
  SuspendedUserInformation:
    "El usuario está suspendido, por favor reactivarlo para ver la información",
  ConfirmReactivation: "Confirmar reactivación",
  ConfirmSuspension: " Confirmar suspensión",
  AreYouSureDelete: "¿Está seguro que desea eliminar este",
  AreYouSureDelete1: "¿Está seguro que desea eliminar estos",
  ToConfirmText: "Confirme que desea eliminar esta información escribiendo la palabra",
  DeniedSuspended: "Denegado (Suspendido)",
  TheLinkExpired: "El enlace expiró",
  Appliances: "Accesorios",
  AppliancesDetails: "Detalles de los accesorios",
  VideoSources: "Fuentes de video",
  AppliancesInfo: "Información del Accesorio",
  StreamURL: "URL de transmisión",
  AddVideoSource: "Agregar fuente de video",
  AppendURL: " Adjuntar URL",
  IPCannotLetters: "La IP no puede contener letras",
  UnmodifiedFields: "Ninguno de los campos fue modificado",
  error_gateway_hasno_streams: "La puerta de enlace no tiene fuentes de video",
  ChangeGateway: "Cambiar puerta de enlace",
  ChangeVideoSource: "Cambiar fuente de video",
  SelectNameAnotherGateway:
    "Seleccione el nombre de la puerta de enlace donde desea mover la fuente de video",
  Enabled: "Habilitar",
  SameSerialNumber: "La puerta de enlace está actualmente asociado con la fuente de video",
  error_invalid_expiration: "Error caducidad no válida",
  ThereNotVideoSource: "No hay fuentes de video disponibles",
  Directory: "Directorio",
  EditDirectory: "Editar Directorio",
  DropToFindTheRangeOfTime: "Soltar para encontrar el rango de tiempo",
  intercom_call_request: "Solicitud de llamada intercomunicador",
  UnitType: "Tipo de Unidad",
  EntryId: "ID de entrada",
  Unit: "Unidad",
  Apt: "Apartamento",
  Home: "Casa",
  Intercoms: "Intercomunicadores",
  ThisOperationCanTakeTime: "Esta operación puede llevar tiempo",
  InPorgress: "En progreso",
  Lobby: "Vestíbulo",
  FirmwareVersion: "Versión de firmware",
  ThereAreNotCatalog: "No existe ningún directorio con ese nombre",
  NoAudio: "La cámara no tiene opción de audio",
  Mute: " Activar silencio",
  Unmute: "Desactivar silencio",
  InsufficientDataAvailable:
    "Datos insuficientes disponibles para reproducir el siguiente segmento",
  ExpirationDate: "Fecha de expiración",
  Until: "Hasta",
  AddPin: "Agregar PIN",
  error_pin_expiration: "Error, PIN expirado",
  AddNewPIN: "Agregar nuevo PIN",
  OutDoorDevice: "Dispositivo al aire libre",
  PlaybackAudioEnabled: "Habilitar reproducción de audio",
  wf_integration_error: "Error de integración",
  DeviceDetails: "Detalles del dispositivo",
  IntercomInfo: "Información del intercomunicador",
  Qrcode: "Código QR",
  AddQrCode: "Agregar código QR",
  AddNewQrCode: "Agregar nuevo código QR",
  playbackAudioDisabled: "Al menos una de las opciones de vídeo debe estar habilitada",
  DeviceName: "Nombre del dispositivo",
  HealthCheck: "Verificar dispositivo",
  EnableTheInputWeingand:
    "Habilite la opción de entrada wiegand en la configuración del dispositivo",
  NoneUnitType: "N/A",
  WIFISettings: "Configuración de WIFI",
  SSDI: "SSDI",
  CannotExceed: "No puede exceder más de 25 caracteres",
  WIFIConnected: "WiFi conectado",
  OSDP: "OSDP",
  DefaultKey: "Clave predeterminada",
  CurrentKey: "Clave actual",
  BaudRates: "Velocidades en baudios",
  Adress: "Dirección",
  error_invalid_osdp_key: "error clave osdp no válida",
  DisableRelay: "OSDP habilitado. No hay opciones de relés disponibles",
  DisableDoorContactPosition: "OSDP habilitado. La única opción disponible es Ninguno",
  DisableExitButtonPositionExternal:
    "OSDP habilitado. No hay opciones de botones de salida disponibles",
  DisableOptions: "Por favor, verifique las recomendaciones mencionadas para la actualización",
  WifiDeviceConnected: "Dispositivo conectado en WIFI",
  WifiDeviceDisconnected: "Dispositivo desconectado de WIFI",
  RangeAddressOSDP: "Rango [0-126]",
  StartDateLaterThanExpirationDate:
    "La fecha de inicio no puede ser posterior a la fecha de expiración",
  InvalidKey: "El valor debe tener 32 caracteres",
  DeliveryEntry: "Entrada de entrega",
  OptionIntercom: "Opción sólo para dispositivos de intercomunicación",
  OnlyOneVisitors: "Los visitantes solo pueden tener un ",
  error_pin_guest_expiration_inconsistent:
    "Los tiempos de PIN válidos deben estar dentro del rango de tiempo activo del visitante",
  SetupIntercomPresentation: "Configuración del mensaje de bienvenida del intercomunicador.",
  MessageMax100: "Mensaje (Máx. 100 caracteres)",
  IntercomMessage: "Mensaje de el Intercomunicador",
  UploadImage: "Cargar imagen",
  MessageEmpty: "El nombre del mensaje no puede estar vacío",
  UnmodifiedMessage: "El mensaje no fue modificado",
  WebhookInactive: "Webhook inactivo",
  GenerateQRCode: "Generar código QR",
  Print: "Imprimir",
  VirtualDirectory: "Código QR del directorio virtual",
  Cloud: "Nube",
  error_user_missing_required_schedule:
    "Error, el usuario no tiene acceso a uno o más dispositivos",
  ConnectionStatus: "Estado de la conexión",
  Secure: "Segura",
  Messages: "Mensajes",
  MassMessaging: "Mensajería Masiva",
  MassMessagingSetup: "Mensajería masiva mediante texto, correo electrónico o mensaje de voz.",
  VoiceAndText: "Voz & Texto",
  IntercomWelcomeMessage: "Mensaje Bienvenida del Intercomunicador",
  Message: "Mensaje",
  ErrorMax300: "Max. 300 caracteres",
  Sent: "Enviado",
  Communication: "Comunicación",
  Definemessagerecipients: "Definir destinatarios del mensaje",
  Selecthowyouwillsendthemessage: "Seleccione cómo enviará el mensaje",
  Writethemessagethatyouwantsend: "Escribe el mensaje que deseas enviar (Máximo 300 caracteres)",
  Elevator: "Ascensor",
  ElevatorSettings: "Ajustes del ascensor",
  Floors: "Pisos",
  CreateOne: "Crear uno",
  CreateMultiple: "Crear Múltiples",
  AddFloors: "Agregar pisos",
  CreateOneFloor: "Crear un piso",
  EditFloor: "Editar piso",
  NumberOfFloors: "Número de pisos",
  error_relay_in_use: "Error, el relé está en uso, debes quitar la relación con los pisos",
  error_relays_attached: "Error, hay pisos asignados a ECUs",
  ElevatorController: "Unidad controladora del ascensor (ECU)",
  error_invalid_relay_panel_address: "Error, dirección de panel de relé no válida",
  NumberOfRelays: "Cantidad de relés del panel",
  PanelAddress: "Dirección del ECU",
  RelayReleaseTime: "Tiempo de liberación del relé",
  AddElevator: "Agregar ECU",
  AssignRelaysToFloors: "Asignar relés a pisos",
  EditRelayPanel: "Editar panel de relé",
  PanelAddressRange: "Rango de direcciones del ECU: [1-7]",
  RelaysPerFloors: "Relé por piso",
  error_floor_not_found: "Error, piso no encontrado",
  ConfirmAutogenerate: "Confirmar Autogeneración",
  DeleteAll: "Eliminar todo",
  RelaysTable: "Tabla de relés",
  error_invalid_number_of_floors: "Error, número de pisos no válido",
  error_floor_create: "Error, crear piso",
  error_schedules_attached: "Error, horarios adjuntos",
  error_floor_delete: "Error, eliminar piso",
  error_floor_update: "Error, actualización de piso",
  error_reused_relay_panel_address: "Error, dirección del panel de relé reutilizada",
  error_relay_panel_not_created: "Error, panel de relés no creado",
  error_relay_panel_not_found: "Error, panel de relé no encontrado",
  error_relays_not_deleted: "Error, relés no eliminados",
  error_relay_panel_not_deleted: "Error, panel de relés no eliminado",
  error_relay_panel_not_updated: "Error, el panel de relés no está actualizado",
  error_relay_floor: "Error, relé de piso",
  FloorsInTheSchedule: "Pisos en el Horario",
  ConfirmRemoveTheFloorFromTheSchedule: "Confirmar la eliminación del piso del schedule",
  FloorsThereNotInTheSchedule: "Los pisos que no están en el horario",
  ThereAreNotFloorsToAdd: "No hay pisos para agregar",
  ThereAreNotFloorsToRemove: "No hay pisos para eliminar",
  ConfirmAddAllFloorsToTheSchedule: "Confirmar la adición de todos los pisos al horario",
  ConfirmRemoveAllFloorsFromTheSchedule: "Confirmar la eliminación de todos los pisos del horario",
  UsersAssignedToTheFloor: "Usuarios asignados al piso",
  Autoassign: "Autoasignar",
  Floor: "Piso",
  RemoveFromFloorSchedule:
    "Debes ir a la pestaña 'Info' en los detalles del dispositivo. Luego, ve a la sección de ascensores y elimina la relación entre relés y pisos haciendo clic en el botón 'Asignar relés por pisos",
  error_catalogs_attached: "Error, catálogos adjuntos",
  error_relay_information: "Error, retransmitir información",
  error_relay_time: "Error, tiempo de relé",
  RangeReleaseTime: "Rango de tiempo de activación del piso: [1-60]",
  ActivationTime: "Tiempo de activación (segundos)",
  Notifications: "Notificaciones",
  PassRequiresApproval: "Los pases requieren aprobación",
  ApprovalRequest: "Solicitud de Aprobación",
  ApprovalRequests: "Solicitudes de Aprobaciones",
  PendingApprovalRequest: "Solicitud de Aprobación Pendientes",
  Request: "Solicitud",
  Requests: "Solicitudes",
  error_request_not_found: "Error, solicitud no encontrada",
  error_request_not_changeable: "Error, solicitud no modificable",
  error_invalid_request_type: "Error, tipo de solicitud invalidad",
  error_invalid_request_status: "Error, status de solicitud invalidad",
  error_request_expired: "Error, expiró la solicitud",
  ApproveRejectUserAccessToTheirVisitors: "Aprobar/Rechazar la solicitud del usuario",
  Approve: "Aprobar",
  Reject: "Rechazar",
  Approved: "Aprobado",
  Rejected: "Rechazado",
  Requester: "Solicitante",
  ExcludeExpired: "Excluir Expirados",
  IncludeExpired: "Incluir Expirados",
  ConfirmApproval: "Confirmar Aprobación",
  ConfirmRejection: " Confirmar Rechazo",
  ModifiedAt: "Modificado en",
  SentQRCode: "Envió Código QR",
  Delivery: "Entrega",
  Requested: "Solictado",
  FloorsNotWorkWithOpenAutomatic:
    "(Pisos deshabilitado porque la 'apertura automática de puertas' está activa)",
  InformationToDelete: "Información para eliminar",
  AllFloors: "Todos los pisos",
  ChooseYourSite: "Elige tu sitio",
  error_floor_duplicated: "Error, piso duplicado",
  error_pin_not_created: "Error, PIN no creado",
  error_pin_not_deleted: "Error, PIN no eliminado",
  error_pin_not_found: "Error, PIN no encontrado",
  error_pin_not_updated: "Error, PIN no actualizado",
  error_venue_not_found: "Error, PIN no encontrado",
};

export default translationES;
